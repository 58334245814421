import Checkbox from '@core-ui/checkbox';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { formatTime } from 'src/app/utils/dates';
import Amount from 'src/components/Amount';
import CurrencyCode from 'src/components/CurrencyCode';
import ExpandMore from 'src/components/ExpandMore';
import StatusBadge from 'src/components/StatusBadge';
import { toggleInvoiceId } from 'src/pages/backoffice/Requests/SingleRequest/InvoicesList/actions';
import TotalTooltip from 'src/pages/backoffice/Requests/SingleRequest/InvoicesList/components/TotalTooltip';
import { IInvoice, INVOICE_CALCULATED_STATUS_ENUM } from 'src/pages/backoffice/Requests/types';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

interface IProps {
  invoice: IInvoice;
  expanded: boolean;
  onExpand: () => void;
}

const InvoiceRow: FC<IProps> = (props) => {
  const { invoice, expanded, onExpand } = props;

  const dispatch = useDispatch();

  const invoiceStatus = invoice.calculated_status;
  const invoiceDeclined = invoiceStatus === INVOICE_CALCULATED_STATUS_ENUM.DECLINED;

  const handleCheckboxChange = () => dispatch(toggleInvoiceId(invoice.id));

  return (
    <Grid item container alignItems="center" xs={12} columnGap={1} padding="16px 0 16px 16px">
      <Checkbox onChange={handleCheckboxChange} size="large" />

      <Grid item width="36px">
        <ExpandMore expand={expanded} onClick={onExpand} aria-expanded={expanded} aria-label="show more">
          <ExpandMoreIcon />
        </ExpandMore>
      </Grid>

      <Grid item xs={2}>
        <Typography
          variant="body1"
          fontWeight="semiBold"
          color={invoiceDeclined ? 'text.secondary' : 'text.primary'}
          sx={{ cursor: 'pointer' }}
          onClick={onExpand}
        >
          {invoice.vendor}
        </Typography>
      </Grid>

      <Grid item xs={1}>
        <Typography variant="body1" color={invoiceDeclined ? 'text.secondary' : 'text.primary'}>
          {invoice.lead_days && (
            <FormattedMessage id="request_detail.invoices.days" values={{ days: invoice.lead_days }} />
          )}
        </Typography>
      </Grid>

      <Grid item xs={1}>
        {invoice.delivery_due && (
          <Typography variant="body1" color={invoiceDeclined ? 'text.secondary' : 'text.primary'}>
            {formatTime(invoice.delivery_due * 1000, { dateFormat: 'dd.MM.yyy', utc: true })}
          </Typography>
        )}
      </Grid>

      <Grid item xs={1}>
        {/* пока отсутсвует */}
      </Grid>

      <Grid item xs={1}>
        {invoice.issue_date && (
          <Typography variant="body1" color={invoiceDeclined ? 'text.secondary' : 'text.primary'}>
            {formatTime(invoice.issue_date * 1000, { dateFormat: 'dd.MM.yyy', utc: true })}
          </Typography>
        )}
      </Grid>

      <Grid item xs={2}>
        {invoice.calculated_status && <StatusBadge status={invoice.calculated_status} />}
      </Grid>

      <Grid item xs={1} marginLeft="auto" textAlign="right">
        <CurrencyCode currencyId={invoice.total_price_currency_id} />
      </Grid>

      <Grid container item xs={1} justifyContent="flex-end" marginLeft="auto" textAlign="right" paddingRight={6}>
        <Tooltip title={<TotalTooltip document={invoice} />} placement="top" arrow>
          <Grid item style={{ cursor: 'pointer' }}>
            <Typography
              variant="body1"
              fontWeight="semiBold"
              color={invoiceDeclined ? 'text.secondary' : 'text.primary'}
            >
              <Amount amount={invoice.subtotal} currencyId={invoice.total_price_currency_id} />
            </Typography>
          </Grid>
        </Tooltip>
      </Grid>

      <Grid container item xs={1} justifyContent="flex-end" marginLeft="auto" textAlign="right" paddingRight={6}>
        <Tooltip title={<TotalTooltip document={invoice} />} placement="top" arrow>
          <Grid item style={{ cursor: 'pointer' }}>
            <Typography
              variant="body1"
              fontWeight="semiBold"
              color={invoiceDeclined ? 'text.secondary' : 'text.primary'}
            >
              <Amount amount={invoice.total_price} currencyId={invoice.total_price_currency_id} />
            </Typography>
          </Grid>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export default InvoiceRow;
