import { useQuery } from '@core-ui/redux-router';
import { buildURL, updateQuery } from '@core-ui/url';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { ROUTES } from 'src/app/consts/routes';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { BACKOFFICE_TABS } from 'src/pages/backoffice/consts';
import QuotesListView from 'src/pages/backoffice/Requests/SingleRequest/QuotesList/components/QuotesListView';
import {
  DETAIL_REQUEST_TABS,
  QUOTES_COMPARE,
  QUOTES_TAB_NAME,
  SingleRequestQueryParams,
} from 'src/pages/backoffice/Requests/SingleRequest/types';
import { FC, useEffect } from 'react';
import { Redirect } from 'react-router';
import { useHistory } from 'react-router-dom';
import QuotesCompare from '../QuotesCompare';
import NoQuotes from './components/NoQuotes';
import selector from './selector';
import useStyles from './styles';

interface IProps {
  requestId: number;
  isEmergency: boolean;
}

const QuotesList: FC<IProps> = (props) => {
  const { requestId, isEmergency } = props;

  const { quotes, boatId, quotesBeingDigitized } = useAppSelector(selector);
  const { quotes_tab } = useQuery<SingleRequestQueryParams>();

  const styles = useStyles();
  const history = useHistory();

  useEffect(() => {
    if (isEmergency || quotes_tab) {
      return;
    }

    history.replace(
      updateQuery({
        [QUOTES_TAB_NAME]: QUOTES_COMPARE.COMPARE,
      })
    );
  }, []);

  /* Если реквест = emergency, никогда не показываем таб квот, но если
   руками вбить урл он откроется. Поэтому так.
   */
  if (isEmergency) {
    return (
      <Redirect
        to={buildURL(
          `/${boatId}/${ROUTES.BACKOFFICE}/${BACKOFFICE_TABS.REQUESTS})/${requestId}/${DETAIL_REQUEST_TABS.ABOUT}`
        )}
      />
    );
  }

  return quotes.length || quotesBeingDigitized.length ? (
    <Grid container component={Paper} sx={styles.container}>
      {quotes_tab === QUOTES_COMPARE.LIST && (
        <QuotesListView quotes={quotes} quotesBeingDigitized={quotesBeingDigitized} />
      )}

      {quotes_tab === QUOTES_COMPARE.COMPARE && <QuotesCompare />}
    </Grid>
  ) : (
    <NoQuotes />
  );
};

export default QuotesList;
