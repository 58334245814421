import { createSelector } from 'reselect';

import State from 'src/app/types/state';
import { getFilters } from 'src/pages/backoffice/Requests/RequestFilters/selector';

const featuresSelector = (state: State) => state.backoffice.requests.featuresList.value;

const selectedFeaturesSelector = (state: State) => state.backoffice.requests.filters.selectedFeatures;

const showMapSelector = createSelector([getFilters], (filters) => filters.showMap);

export default createSelector(
  [featuresSelector, showMapSelector, selectedFeaturesSelector],
  (features, showMap, selectedFeatures) => ({
    features,
    showMap,
    selectedFeatures,
  })
);
