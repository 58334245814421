import { createParseQuery, resetState, setFieldFilter } from '@core-ui/reducers_handlers';
import { createAction } from 'redux-actions';

export const reducerName = 'requests.filters';

export const setTextFilter = setFieldFilter(reducerName);
export const resetRequestFilters = resetState(reducerName);

export const updateRequestsFiltersQuery = createAction(`${reducerName}.UPDATE_REQUESTS_FILTERS_QUERY`);
export const setRequestsFiltersFromQuery = createParseQuery(reducerName);
