import { getAmountFormatterWithCurrencyCodeById } from 'src/dictionary/selector';
import { getSingleWalletListState } from 'src/pages/backoffice/Wallets/SingleWalletList/selector';
import { getSingleWallet } from 'src/pages/backoffice/Wallets/SingleWalletRoot/selector';
import { createSelector } from 'reselect';

export default createSelector(
  [getSingleWallet, getSingleWalletListState, getAmountFormatterWithCurrencyCodeById],
  (singleWallet, documentsList, currencyFormatter) => ({
    wallet: singleWallet.value,
    formattedAmountWithCurrency: currencyFormatter(singleWallet.value?.currency_id, documentsList.total),
  })
);
