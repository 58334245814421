import { createSelector } from 'reselect';

import State from 'src/app/types/state';
import { getSelectedStatuses } from 'src/pages/backoffice/Requests/RequestFilters/selector';

import { CALCULATED_STATUS } from './types';

const requestsSelector = (state: State) => state.backoffice.requests.list.value || [];
const getNameFilter = (state: State) => state.backoffice.requests.filters.name;
const selectedFeatureSelector = (state: State) => state.backoffice.requests.filters.selectedFeatures;

export const makeStatusSelector = (status: CALCULATED_STATUS) =>
  createSelector(
    [requestsSelector, getNameFilter, selectedFeatureSelector],
    (requests, nameFilter, featuresSelected) => {
      const filtered = requests.filter(
        (r) => r.calculated_status === status && (featuresSelected.length ? featuresSelected.includes(r.id) : true)
      );

      return nameFilter ? filtered.filter((r) => r.name.toLowerCase().includes(nameFilter.toLowerCase())) : filtered;
    }
  );

const getFilters = (state: State) => state.backoffice.requests.filters;

export const getFilteredGroupedStatusList = createSelector([getFilters, getSelectedStatuses], (filters, statuses) => {
  return statuses.map((status) => ({
    title: status.label,
    selector: makeStatusSelector(status.value),
  }));
});
