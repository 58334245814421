import { buildURL } from '@core-ui/url';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { ROUTES } from 'src/app/consts/routes';
import { vehicleIdSelector } from 'src/app/selectors';
import { formatTime } from 'src/app/utils/dates';
import Department from 'src/components/Department';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { BACKOFFICE_TABS } from 'src/pages/backoffice/consts';
import { DETAIL_REQUEST_TABS } from 'src/pages/backoffice/Requests/SingleRequest/types';
import { IPaymentRequest } from 'src/pages/backoffice/Requests/types';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import RequestApproveStatusBadge from '../../../components/RequestApproveStatusBadge';
import useStyles from './styles';

interface IProps {
  item: IPaymentRequest;
}

const TableRow: FC<IProps> = (props) => {
  const { item } = props;

  const classes = useStyles();
  const boatId = useAppSelector(vehicleIdSelector);

  const uploadedByName = [item.employee_first_name, item.employee_last_name].filter(Boolean).join(' ') || (
    <FormattedMessage id="label.no_info" />
  );

  return (
    <Grid container wrap="nowrap" justifyContent="space-between" padding="12px 14px" className={classes.row}>
      <Grid
        item
        xs={4}
        component={Link}
        to={buildURL(
          `/${boatId}/${ROUTES.BACKOFFICE}/${BACKOFFICE_TABS.REQUESTS}/${item.id}/${DETAIL_REQUEST_TABS.ABOUT}`
        )}
        className={classes.name}
      >
        <Typography variant="body1">{item.name}</Typography>
      </Grid>

      <Grid item component={Typography} xs noWrap>
        <RequestApproveStatusBadge status={item.approve_status} />
      </Grid>

      <Grid item container xs zeroMinWidth wrap="nowrap" columnGap={1} alignItems="center">
        {/* todo: add avatar src when they are ready on the backend */}
        <Avatar
          variant="rounded"
          sx={{ width: 18, height: 18, marginLeft: '-25px' }}
          // src={`${employee?.avatar_id}`}
        />

        <Typography variant="body1" noWrap>
          {uploadedByName}
        </Typography>
      </Grid>

      <Grid item component={Typography} xs noWrap>
        <Department departmentId={item.department_id} />
      </Grid>

      <Grid item component={Typography} xs noWrap>
        <Typography variant="body1">
          {item.created_at && formatTime(item.created_at * 1000, { dateFormat: 'dd.MM.yyy' })}
        </Typography>
      </Grid>

      <Grid item component={Typography} xs noWrap>
        <Typography variant="body1">
          {item.expired_at && formatTime(item.expired_at * 1000, { dateFormat: 'dd.MM.yyy' })}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default TableRow;
