import { getList, resetState, setList, setLoading } from '@core-ui/reducers_handlers';

import { IOpenLayerFeature, IPaymentRequest } from '../types';

export const reducerName = 'requests.list';
export const featuresListReducerName = 'requests.featuresList';

export const getRequestsList = getList(reducerName);
export const setRequestsList = setList<IPaymentRequest>(reducerName);

export const getFeaturesList = getList(featuresListReducerName);
export const setFeaturesList = setList<IOpenLayerFeature>(featuresListReducerName);

export const resetRequestsList = resetState(reducerName);

export const setRequestsLoading = setLoading(reducerName);
