import { Nullable } from '@core-ui/types';
import { IQuote } from 'src/pages/backoffice/Requests/types';
import { put, select, takeLatest } from 'redux-saga/effects';

import { IQuoteChecked } from '../types';
import * as actions from './actions';
import { getDocuments, getItem, quotesItems } from './selector';

function* setCompareView() {
  const quotes: IQuote[] = yield select(quotesItems);

  const quotesId: Record<number, IQuoteChecked> = {};
  quotes.forEach((element) => {
    quotesId[element.id] = { checked: true };
  });

  yield put(actions.setCompareQuotes({ checkedQuoteIds: quotesId, quotes }));
}

function* updateQuotes() {
  const quotesId: Nullable<Record<number, IQuoteChecked>> = yield select(getItem);
  const quotes: IQuote[] = yield select(getDocuments);
  const newQuotes: IQuote[] = [];

  if (!quotesId) {
    return;
  }

  quotes.forEach((element) => {
    if (quotesId[element.id].checked) {
      newQuotes.push(element);
    }
  });

  yield put(actions.setCompareQuotes({ checkedQuoteIds: quotesId, quotes: newQuotes }));
}

export default [takeLatest(actions.setCompareView, setCompareView), takeLatest(actions.updateQuotes, updateQuotes)];
