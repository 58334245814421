import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(
  {
    paper: {
      borderRadius: 8,
      padding: '14px 16px',
    },
  },
  { name: 'RequestCard' }
);
