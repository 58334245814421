import Grid from '@mui/material/Grid';
import { IInvoice } from 'src/pages/backoffice/Requests/types';
import React, { FC, useState } from 'react';
import InvoiceRow from 'src/pages/backoffice/Requests/SingleRequest/InvoicesList/components/InvoiceRow';
import ItemExpanded from 'src/pages/backoffice/Requests/SingleRequest/components/ItemExpanded';
import InvoiceButtons from '../InvoiceButtons';
import useStyles from './styles';

export interface IProps {
  invoice: IInvoice;
}

const InvoiceDigitizedItem: FC<IProps> = (props) => {
  const { invoice } = props;

  const [expanded, setExpanded] = useState(false);
  const styles = useStyles({ expanded });

  const handleExpandClick = () => {
    setExpanded((prevState) => !prevState);
  };

  return (
    <Grid item container xs={12} sx={styles.root}>
      <InvoiceRow invoice={invoice} expanded={expanded} onExpand={handleExpandClick} />

      <ItemExpanded expanded={expanded} document={invoice} />

      <InvoiceButtons invoice={invoice} />
    </Grid>
  );
};

export default InvoiceDigitizedItem;
