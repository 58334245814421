import { IQuote, IQuoteLine } from 'src/pages/backoffice/Requests/types';
import { getBars } from 'src/pages/backoffice/Requests/SingleRequest/QuotesCompare/utils';

export const getLines = (quote: IQuote, lines: IQuoteLine[]) => {
  const bars = getBars(quote);

  return lines
    .map((article) => bars.find((bar) => bar.cost_article === article.cost_article))
    .filter(Boolean) as IQuoteLine[];
};
