import React, { FC } from 'react';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import CardContainer from 'src/pages/backoffice/Requests/RequestList/TileViewRequests/components/CardContainer';

const list = new Array(4).fill(null);
const paperStyle = { height: 135, marginTop: 4 };

const TileViewSkeleton: FC = () => {
  return (
    <>
      {list.map((_, idx) => (
        <CardContainer key={idx}>
          <Box width="100%">
            <Skeleton variant="text" />
            <Paper elevation={0} style={paperStyle} />
            <Paper elevation={0} style={paperStyle} />
          </Box>
        </CardContainer>
      ))}
    </>
  );
};

export default TileViewSkeleton;
