import React, { FC } from 'react';
import { Selector } from 'reselect';

import Badge from '@mui/material/Badge';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import State from 'src/app/types/state';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { IPaymentRequest } from 'src/pages/backoffice/Requests/types';

import TableRow from '../TableRow';

interface IProps {
  title: string;
  selector: Selector<State, IPaymentRequest[]>;
}

const TableView: FC<IProps> = (props) => {
  const { title, selector } = props;

  const items = useAppSelector(selector);

  return (
    <Grid container marginTop="24px">
      <Grid item container xs={12} marginBottom="16px" alignItems="center" wrap="nowrap" columnGap={4}>
        <Typography variant="h6">{title}</Typography>
        <Badge badgeContent={items.length || '0'} color="secondary" />
      </Grid>
      <Grid item xs={12}>
        {items.map((item) => (
          <TableRow key={item.id} item={item} />
        ))}
      </Grid>
    </Grid>
  );
};

export default TableView;
