import React, { FC, CSSProperties } from 'react';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import useStyles from './styles';

export interface IProps {
  style?: CSSProperties;
}

const CardContainer: FC<IProps> = (props) => {
  const { children } = props;

  const styles = useStyles(props);

  return (
    <Grid item container component={Paper} variant="outlined" sx={styles.root}>
      {children}
    </Grid>
  );
};

export default CardContainer;
