import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { COLOURS } from '@core-ui/styles';

import { IProps } from './CardContainer';

export const PADDING_TOP = 16;
export const PADDING_BOTTOM = 8;

export default ({ style }: IProps) =>
  ({
    root: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      flexWrap: 'nowrap',
      height: style?.height ?? 'fit-content',
      maxWidth: 320,
      flex: '1 0 320px',
      backgroundColor: COLOURS.CLEAR,
      borderRadius: '12px',
      padding: `${PADDING_TOP}px 12px ${PADDING_BOTTOM}px 12px`,

      '&:not(:first-of-type)': {
        marginLeft: 4,
      },
    },
  } as Record<string, SxProps<Theme>>);
