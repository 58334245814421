import { HEIGHT_SIZE } from '@core-ui/styles';
import Container from '@mui/material/Container';
import { SxProps, Theme } from '@mui/material/styles';
import { exhaustiveGuard } from 'src/app/utils/app';
import { useAppSelector } from 'src/hooks/useAppSelector';
import RequestFilter from 'src/pages/backoffice/Requests/RequestFilters';
import { IFiltersView } from 'src/pages/backoffice/Requests/RequestFilters/reducer';
import { getFiltersView } from 'src/pages/backoffice/Requests/RequestFilters/selector';
import TableViewRequests from 'src/pages/backoffice/Requests/RequestList/TableViewRequests';
import TileViewRequests from 'src/pages/backoffice/Requests/RequestList/TileViewRequests';
import React from 'react';

const tileContainerStyle: SxProps<Theme> = {
  height: `calc(100vh - (${HEIGHT_SIZE.headerHeight + HEIGHT_SIZE.tabsHeight}px))`,
};

const tableContainerStyle: SxProps<Theme> = {
  padding: '0 24px !important',
  height: `calc(100vh - ${HEIGHT_SIZE.headerHeight}px)`,
};

const RequestsRoot = () => {
  const filtersView: IFiltersView = useAppSelector(getFiltersView);

  switch (filtersView) {
    case 'tile':
      return (
        <Container maxWidth={false} sx={tileContainerStyle}>
          <Container maxWidth="xl">
            <RequestFilter />
          </Container>

          <TileViewRequests />
        </Container>
      );

    case 'table':
      return (
        <Container maxWidth="xl" sx={tableContainerStyle}>
          <RequestFilter />

          <TableViewRequests />
        </Container>
      );

    default:
      return exhaustiveGuard(filtersView);
  }
};

export default RequestsRoot;
