import { ScrollMemory } from '@core-ui/redux-router';
import { BREAKPOINTS } from '@core-ui/styles';
import Grid from '@mui/material/Grid';
import { SxProps, Theme } from '@mui/material/styles';
import { useAppSelector } from 'src/hooks/useAppSelector';
import useScrollOnDrag from 'src/hooks/useScrollOnDrag';
import { TileViewSkeleton } from 'src/pages/backoffice/Requests/skeletons';
import React, { FC } from 'react';
import Cards from './components/Cards';
import selector from './selector';

const SCROLLABLE_CONTAINER_ID = 'scrollable-container';

const containerStyle: SxProps<Theme> = {
  height: '100%',
  overflow: 'scroll',
  flexWrap: 'nowrap',
  paddingBottom: '12px',
};

// fixme: вынести 12px в константу. используется для паддингов в шапке, табах, контейнере
const padding = 12;
// fixme: safari по какой-то причине не рисует margin справа на последнем элементе, поэтому используем span
const spanStyle = { width: `max(calc((100% - ${BREAKPOINTS.XL}px) / 2 + ${padding}px), ${padding}px)`, flexShrink: 0 };

const TileViewRequests: FC = () => {
  const { hasData, groupedStatusList } = useAppSelector(selector);
  const { ref } = useScrollOnDrag();

  return (
    <>
      <ScrollMemory elementID={SCROLLABLE_CONTAINER_ID} />

      <Grid ref={ref} item container sx={containerStyle} id={SCROLLABLE_CONTAINER_ID}>
        <span style={spanStyle} />
        {hasData ? (
          <>
            {groupedStatusList.map((groupedStatus) => (
              <Cards key={groupedStatus.title} title={groupedStatus.title} selector={groupedStatus.selector} />
            ))}
          </>
        ) : (
          <TileViewSkeleton />
        )}
        <span style={spanStyle} />
      </Grid>
    </>
  );
};

export default TileViewRequests;
