import { createParseQuery, resetState, setFieldFilter } from '@core-ui/reducers_handlers';
import { createAction } from 'redux-actions';

export const reducerName = 'wallets.filters';

export const setTextFilter = setFieldFilter(reducerName);
export const resetWalletsFilters = resetState(reducerName);

export const updateWalletsFiltersQuery = createAction(`${reducerName}.UPDATE_WALLETS_FILTERS_QUERY`);
export const setWalletsFiltersFromQuery = createParseQuery(reducerName);
