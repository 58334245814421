import Checkbox from '@core-ui/checkbox';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { formatTime } from 'src/app/utils/dates';
import Amount from 'src/components/Amount';
import CurrencyCode from 'src/components/CurrencyCode';
import ExpandMore from 'src/components/ExpandMore';
import StatusBadge from 'src/components/StatusBadge';
import { setCheckedQuoteById } from 'src/pages/backoffice/Requests/SingleRequest/QuotesCompare/actions';
import { IQuote, QUOTE_STATUS_ENUM } from 'src/pages/backoffice/Requests/types';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import TooltipTitle from '../TooltipTitle';

interface IProps {
  document: IQuote;
  expanded: boolean;
  onExpand: () => void;
  checked?: boolean;
  isCompare?: boolean;
}

const QuoteRow: FC<IProps> = (props) => {
  const { document, expanded, onExpand, checked, isCompare } = props;

  const dispatch = useDispatch();

  const quoteStatus = document.document_approve_status;
  const quoteDeclined = quoteStatus === QUOTE_STATUS_ENUM.DECLINED;

  const handleCheckboxClick = () => {
    dispatch(setCheckedQuoteById({ id: document?.id, checked: !checked }));
  };

  return (
    <Grid container alignItems="center" columnGap={1} padding="16px 0">
      {isCompare && (
        <Grid item>
          <Checkbox checked={checked} onClick={handleCheckboxClick} size="large" />
        </Grid>
      )}

      <Grid item width="36px">
        <ExpandMore expand={expanded} onClick={onExpand} aria-expanded={expanded}>
          <ExpandMoreIcon />
        </ExpandMore>
      </Grid>

      <Grid item xs={2}>
        <Typography
          variant="body1"
          fontWeight="semiBold"
          color={quoteDeclined ? 'textSecondary' : 'textPrimary'}
          sx={{ cursor: 'pointer' }}
          onClick={onExpand}
        >
          {document.vendor}
        </Typography>
      </Grid>

      <Grid item xs={1}>
        {/* пока отсутсвует */}
      </Grid>

      <Grid item xs={1}>
        {document.delivery_due && (
          <Typography variant="body1" color={quoteDeclined ? 'textSecondary' : 'textPrimary'}>
            {formatTime(document.delivery_due * 1000, { dateFormat: 'dd.MM.yyy', utc: true })}
          </Typography>
        )}
      </Grid>

      <Grid item xs={1}>
        {/* пока отсутсвует */}
      </Grid>

      <Grid item xs={1}>
        {document.issue_date && (
          <Typography variant="body1" color={quoteDeclined ? 'textSecondary' : 'textPrimary'}>
            {formatTime((document.issue_date as number) * 1000, { dateFormat: 'dd.MM.yyy', utc: true })}
          </Typography>
        )}
      </Grid>

      <Grid item xs={2}>
        <StatusBadge status={document.document_approve_status} />
      </Grid>

      <Grid item xs={1} marginLeft="auto" textAlign="right">
        <CurrencyCode currencyId={document.total_price_currency_id} />
      </Grid>

      <Grid container item xs={1} justifyContent="flex-end" marginLeft="auto" textAlign="right" paddingRight={6}>
        <Tooltip title={<TooltipTitle document={document} />} placement="top" arrow>
          <Grid item style={{ cursor: 'pointer' }}>
            <Typography variant="body1" fontWeight="semiBold" color={quoteDeclined ? 'textSecondary' : 'textPrimary'}>
              <Amount amount={document.subtotal} currencyId={document.total_price_currency_id} />
            </Typography>
          </Grid>
        </Tooltip>
      </Grid>

      <Grid container item xs={1} justifyContent="flex-end" marginLeft="auto" textAlign="right" paddingRight={6}>
        <Tooltip title={<TooltipTitle document={document} />} placement="top" arrow>
          <Grid item style={{ cursor: 'pointer' }}>
            <Typography variant="body1" fontWeight="semiBold" color={quoteDeclined ? 'textSecondary' : 'textPrimary'}>
              <Amount amount={document.total_price} currencyId={document.total_price_currency_id} />
            </Typography>
          </Grid>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export default QuoteRow;
