import { extractFlattenPaths, Route } from '@core-ui/redux-router';
import { ROUTES } from 'src/app/consts/routes';
import { getClientCostArticleDict, getDepartmentDict, getEmployeeDict } from 'src/dictionary/actions';
import { BACKOFFICE_TABS } from 'src/pages/backoffice/consts';
import { IBackofficeState } from 'src/pages/backoffice/reducer';
import {
  resetRequestFilters,
  setRequestsFiltersFromQuery,
  updateRequestsFiltersQuery,
} from 'src/pages/backoffice/Requests/RequestFilters/actions';
import { getFeaturesList, getRequestsList, resetRequestsList } from 'src/pages/backoffice/Requests/RequestList/actions';
import RequestsRoot from 'src/pages/backoffice/Requests/RequestRoot';
import { getRequest, setRequest } from 'src/pages/backoffice/Requests/SingleRequest/QuotesList/actions';
import SingleRequestRoot from 'src/pages/backoffice/Requests/SingleRequest/SingleRequestRoot';
import { REQUEST_DETAIL_TABS_PATH } from 'src/pages/backoffice/Requests/SingleRequest/types';

const key: keyof IBackofficeState = 'requests';

const routes: Route[] = [
  {
    key,
    exact: true,
    path: `/:boatId([0-9]+)/:route(${ROUTES.BACKOFFICE})/:backofficeTab(${BACKOFFICE_TABS.REQUESTS})`,
    component: RequestsRoot,
    effects: [
      { events: ['on_enter'], trigger: resetRequestsList },
      { events: ['on_enter'], trigger: resetRequestFilters },
      { events: ['on_enter'], trigger: setRequestsFiltersFromQuery },
      { events: ['on_enter'], trigger: updateRequestsFiltersQuery },
      { events: ['on_enter'], trigger: getRequestsList },
      { events: ['on_enter'], trigger: getFeaturesList },
      { events: ['on_enter'], trigger: setRequest },
    ],
  },
  {
    key,
    exact: true,
    path: `/:boatId([0-9]+)/:route(${ROUTES.BACKOFFICE})/:backofficeTab(${BACKOFFICE_TABS.REQUESTS})/:requestId/:tabId(${REQUEST_DETAIL_TABS_PATH})?`,
    component: SingleRequestRoot,
    effects: [
      { events: ['on_enter'], trigger: getRequest },
      { events: ['once'], trigger: getDepartmentDict },
      { events: ['once'], trigger: getEmployeeDict },
      { events: ['once'], trigger: getClientCostArticleDict },
    ],
  },
];

export const REQUEST_PATHS = extractFlattenPaths(routes);

export default routes;
