import contracts, { IContractsState } from 'src/pages/backoffice/Contracts/reducer';
import inbox, { IInboxState } from 'src/pages/backoffice/Inbox/reducer';
import ledger, { ILedgerState } from 'src/pages/backoffice/Ledger/reducer';
import modals, { IModalsState } from 'src/pages/backoffice/Modals/reducer';
import requests, { IRequestsState } from 'src/pages/backoffice/Requests/reducer';
import wallets, { IWalletsState } from 'src/pages/backoffice/Wallets/reducer';
import { combineReducers } from 'redux';

export interface IBackofficeState {
  modals: IModalsState;
  inbox: IInboxState;
  wallets: IWalletsState;
  contracts: IContractsState;
  requests: IRequestsState;
  ledger: ILedgerState;
}

export default combineReducers({
  modals,
  inbox,
  wallets,
  contracts,
  requests,
  ledger,
});
