import { COLOURS } from '@core-ui/styles';
import { formatTime } from 'src/app/utils/dates';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Amount from 'src/components/Amount';
import StatusBadge from 'src/components/StatusBadge';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { IQuote } from 'src/pages/backoffice/Requests/types';
import { FC, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { heightContainerBaseInfo, widthQuoteColumn } from 'src/pages/backoffice/Requests/SingleRequest/consts';
import selector from './selector';
import { getLines } from './utils';
import QuotesCompareMoreButton from '../QuotesCompareMoreButton';

interface IProps {
  quote: IQuote;
}

const styles = { width: 100, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' };

const QuoteCell: FC<IProps> = (props) => {
  const { quote } = props;
  const { articles } = useAppSelector(selector);

  const lines = useMemo(() => getLines(quote, articles), [quote, articles]);

  return (
    <Grid container width={widthQuoteColumn} alignItems="flex-start">
      <Grid height={heightContainerBaseInfo} width="100%" borderBottom={`1px solid ${COLOURS.BORDER.MAIN}`}>
        <Box paddingLeft={6} paddingTop={2}>
          <Grid container wrap="wrap">
            <Grid>
              <Typography fontWeight="semiBold" sx={styles}>
                {quote.vendor}
              </Typography>
            </Grid>

            <Grid>
              <QuotesCompareMoreButton quoteId={quote.id} quoteStatus={quote.document_approve_status} />
            </Grid>
          </Grid>

          <Typography>
            <StatusBadge status={quote.document_approve_status} />
          </Typography>
        </Box>

        <Box paddingLeft={6} paddingTop={6}>
          <Typography>
            <Amount amount={quote.total_price} currencyId={quote.total_price_currency_id} />
          </Typography>

          <Typography paddingTop={3} color="textSecondary">
            {quote.vat && <Amount amount={quote.vat} currencyId={quote?.total_price_currency_id} />}
            {quote.vat_percent && (quote.vat ? ` (${quote?.vat_percent}%)` : ` ${quote?.vat_percent}%`)}
          </Typography>
        </Box>
      </Grid>

      <Grid width="100%" borderBottom={`1px solid ${COLOURS.BORDER.MAIN}`} paddingLeft={6}>
        {lines.map((line) => (
          <Grid key={line.id}>
            <Typography paddingTop={4} paddingBottom={4}>
              {line.total_price ? <Amount amount={line.total_price} currencyId={line.total_price_currency_id} /> : '——'}
            </Typography>
          </Grid>
        ))}
      </Grid>

      <Grid width="100%">
        <Box paddingLeft={6}>
          <Typography paddingTop={4} paddingBottom={4}>
            {quote.due_date ? formatTime(quote.due_date * 1000, { dateFormat: 'dd.MM.yyy' }) : '——'}
          </Typography>

          <Typography paddingTop={4} paddingBottom={4}>
            <FormattedMessage id={`quotes.${quote.document_type}`} />
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default QuoteCell;
