import { getParams } from '@core-ui/redux-router';
import { GET } from 'src/api/oceanApi';
import { responseError } from 'src/app/sagas';
import { ISagaContext } from 'src/app/types/common';
import { WalletOutputSchema } from 'src/generated';
import { WALLETS_PATHS } from 'src/pages/backoffice/Wallets/routes';
import { SingleWalletPathParams } from 'src/pages/backoffice/Wallets/types';
import { call, getContext, put, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';

function* getWallet() {
  const history: ISagaContext['history'] = yield getContext('history');

  try {
    const params = getParams<SingleWalletPathParams>(history.location.pathname, WALLETS_PATHS);

    const wallet: WalletOutputSchema = yield call(GET, `/wallets/${params.walletId}`);

    if (!wallet) {
      throw new Error('No wallet found');
    }

    yield put(
      actions.setWallet({
        value: wallet,
        hasData: true,
      })
    );
  } catch (e) {
    yield call(responseError, e);
    yield put(
      actions.setWallet({
        error: e as Error,
        hasData: false,
      })
    );
  }
}

export default [takeLatest(actions.getWallet, getWallet)];
