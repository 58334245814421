import State from 'src/app/types/state';
import { createSelector } from 'reselect';

const getLoading = (state: State, props: { requestId: string }) =>
  state.backoffice.requests.singleRequest.request.loadingItems?.[props.requestId] ||
  state.backoffice.requests.singleRequest.request.loading;

const getRequestStatusIsArchive = (state: State) => state.backoffice.requests.singleRequest.request.value?.is_archive;

const getUserId = (state: State) => state.app.user?.id;
const getUserIdRequest = (state: State) => state.backoffice.requests.singleRequest.request.value?.employee_id;

export default createSelector(
  [getLoading, getRequestStatusIsArchive, getUserId, getUserIdRequest],
  (isLoading, requestIsArchive, userId, userIdOfRequest) => ({
    isLoading,
    requestIsArchive,
    userId,
    userIdOfRequest,
  })
);
