import { createSelector } from 'reselect';

import { vehicleIdSelector } from 'src/app/selectors';
import { getEmployeeByIdSelector } from 'src/dictionary/selector';

export default () =>
  createSelector([vehicleIdSelector, getEmployeeByIdSelector], (boatId, employee) => ({
    boatId,
    employee,
  }));
