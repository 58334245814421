import Button from '@core-ui/button';
import { buildURL } from '@core-ui/url';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { BACKEND_URL } from 'src/app/consts/env';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { openBackofficeModal } from 'src/pages/backoffice/Modals/actions';
import { SendToErpRequestInvoicesBatchModalData } from 'src/pages/backoffice/Modals/command';
import InvoiceTotal from 'src/pages/backoffice/Requests/SingleRequest/InvoicesList/components/InvoiceTotal';
import { DownloadSimple, PaperPlaneTilt } from '@phosphor-icons/react';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import InvoiceDigitizedItem from './components/InvoiceDigitizedItem';
import InvoiceItemOnDigitized from './components/InvoiceItemOnDigitized';
import InvoiceListHeader from './components/InvoiceListHeader';
import NoInvoices from './components/NoInvoices';
import selector from './selector';
import useStyles from './styles';

// TODO: разобраться с показом кнопки "ask for approval" одновременно с кнопками "approve/decline", такого быть не должно, либо первое, либо второе

interface IProps {
  requestId: number;
}

const InvoicesList: FC<IProps> = (props) => {
  const { requestId } = props;

  const { invoices, invoiceBeingDigitized, isBatchSendBtnDisabled } = useAppSelector(selector);

  const dispatch = useDispatch();
  const styles = useStyles();

  const handleSendAllInvoices = () => {
    dispatch(openBackofficeModal(new SendToErpRequestInvoicesBatchModalData(requestId)));
  };

  return invoices.length || invoiceBeingDigitized.length ? (
    <Grid container component={Paper} sx={styles.container}>
      <Grid container justifyContent="space-between" sx={styles.invoicesHeader}>
        <InvoiceTotal />

        <Grid item>
          <Button
            variant="text"
            color="blue"
            startIcon={<DownloadSimple />}
            label={<FormattedMessage id="download.all.invoices" />}
            href={buildURL(`${BACKEND_URL}/files/requests/${requestId}`, {
              file_type: 'invoice',
              is_digitized: true,
            })}
          />

          <Button
            variant="contained"
            color="blue"
            startIcon={<PaperPlaneTilt />}
            label={<FormattedMessage id="label.send_selected_to_erp" />}
            onClick={handleSendAllInvoices}
            disabled={isBatchSendBtnDisabled}
          />
        </Grid>
      </Grid>

      <InvoiceListHeader />

      <Grid container gap={1} sx={styles.invoicesListHeader}>
        {invoices?.map((invoice) => (
          <InvoiceDigitizedItem key={invoice.id} invoice={invoice} />
        ))}

        {invoiceBeingDigitized?.map((file) => (
          <InvoiceItemOnDigitized key={file.id} document={file} />
        ))}
      </Grid>
    </Grid>
  ) : (
    <NoInvoices />
  );
};

export default InvoicesList;
