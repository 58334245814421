import { COLOURS } from '@core-ui/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import { formatTime } from 'src/app/utils/dates';
import OlMap from 'src/components/OLMap';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { openBackofficeModal } from 'src/pages/backoffice/Modals/actions';
import { ImageCarouselModalData } from 'src/pages/backoffice/Modals/command';
import RequestAuthor from 'src/pages/backoffice/Requests/SingleRequest/RequestInfo/components/RequestAuthor';
import RequestInfoRow from 'src/pages/backoffice/Requests/SingleRequest/RequestInfo/components/RequestInfoRow';
import { getRequestData } from 'src/pages/backoffice/Requests/SingleRequest/RequestInfo/selector';
import { IPaymentRequestFile } from 'src/pages/backoffice/Requests/types';
import { ImageSquare } from '@phosphor-icons/react';
import { CSSProperties, FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import useStyles from './styles';

const imageStyles: CSSProperties = {
  height: 76,
  width: 76,
  cursor: 'pointer',
};

const RequestInfo: FC = () => {
  const request = useAppSelector(getRequestData);

  const dispatch = useDispatch();
  const styles = useStyles();

  const images = request?.files_tree?.reduce<IPaymentRequestFile[]>((acc, file) => {
    const childFiles = file.child_files;

    if (childFiles) {
      return [...acc, ...childFiles];
    }

    return acc;
  }, []);

  const handleImageClick = (idx: number) => () => {
    const files = images?.map((file) => file.link);

    if (files) {
      dispatch(
        openBackofficeModal(new ImageCarouselModalData(files, idx, request?.calculated_status, request?.employee_id))
      );
    }
  };

  return (
    <Grid container component={Paper} sx={styles.container}>
      <Grid container wrap="nowrap">
        <Grid item xs container direction="column" rowGap={3}>
          <RequestInfoRow title="request_detail.type">
            <Typography variant="body1">
              <FormattedMessage id={`request_type.${request?.request_type}`} />
            </Typography>
          </RequestInfoRow>

          <RequestInfoRow title="request_detail.author">
            <RequestAuthor first_name={request?.employee_first_name} last_name={request?.employee_last_name} />
          </RequestInfoRow>

          <RequestInfoRow title="request_detail.creation_date">
            <Typography variant="body1">
              {request?.created_at &&
                formatTime(new Date(request.created_at * 1000), {
                  dateFormat: 'd MMMM yyyy',
                })}
            </Typography>
          </RequestInfoRow>

          <RequestInfoRow title="request_detail.buy_reason">
            <Typography variant="body1" maxWidth={630}>
              {request?.description}
            </Typography>
          </RequestInfoRow>

          <RequestInfoRow title="request_detail.request_files" containerProps={{ container: true, gap: 4 }}>
            {images?.map((file, idx) => (
              <Grid key={file.id} item onClick={handleImageClick(idx)}>
                <img src={file.link} style={imageStyles} alt={file.filename} />
              </Grid>
            ))}

            <Grid container component={Paper} variant="outlined" sx={imageStyles}>
              <SvgIcon htmlColor={COLOURS.HUE_COCONUT.HUE_100} style={{ margin: 'auto' }}>
                <ImageSquare />
              </SvgIcon>
            </Grid>
          </RequestInfoRow>
        </Grid>

        <Grid item xs={6} minHeight={610} maxWidth={640}>
          <OlMap features={request?.features} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RequestInfo;
