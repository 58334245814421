import { COLOURS } from '@core-ui/styles';
import { IMenuOption } from 'src/components/Menu/Menu';
import MoreButton from 'src/components/MoreButton';
import { WalletOutputSchema } from 'src/generated';
import { openBackofficeModal } from 'src/pages/backoffice/Modals/actions';
import { CreateEditWalletModalData, DeleteWalletModalData } from 'src/pages/backoffice/Modals/command';
import { NotePencil, Trash } from '@phosphor-icons/react';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

interface IProps {
  wallet: WalletOutputSchema;
}

const SingleWalletHeaderMoreButton: FC<IProps> = (props) => {
  const { wallet } = props;

  const dispatch = useDispatch();

  const handleEdit = () => {
    dispatch(openBackofficeModal(new CreateEditWalletModalData(wallet, false)));
  };

  const handleDelete = () => {
    dispatch(openBackofficeModal(new DeleteWalletModalData(wallet.id, true)));
  };

  const options: IMenuOption[] = [
    {
      title: <FormattedMessage id="label.edit" />,
      dataTestId: 'single-wallet-more-button-edit',
      icon: <NotePencil />,
      onClick: handleEdit,
      visible: true,
    },
    {
      title: <FormattedMessage id="label.delete" />,
      dataTestId: 'single-wallet-more-button-delete',
      textColor: COLOURS.HUE_STRAWBERRY.HUE_100,
      icon: <Trash color={COLOURS.HUE_STRAWBERRY.HUE_100} />,
      onClick: handleDelete,
      visible: true,
    },
  ];

  return <MoreButton options={options} variant="square" padding={4} dataTestId="single-wallet-header-more-button" />;
};

export default SingleWalletHeaderMoreButton;
