import Button from '@core-ui/button';
import { COLOURS } from '@core-ui/styles';
import Grid from '@mui/material/Grid';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import StatusBadge from 'src/components/StatusBadge';
import { FileStatusEnum } from 'src/generated';
import { openBackofficeModal } from 'src/pages/backoffice/Modals/actions';
import { DeleteInvoiceModalData } from 'src/pages/backoffice/Modals/command';
import { IPaymentRequestFile } from 'src/pages/backoffice/Requests/types';
import { HourglassMedium, Trash } from '@phosphor-icons/react';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

interface IProps {
  document: IPaymentRequestFile;
}

const HourGlassCell: FC = () => (
  <Grid item container xs={1} bgcolor={COLOURS.HUE_COCONUT.HUE_8}>
    <SvgIcon>
      <HourglassMedium />
    </SvgIcon>
  </Grid>
);

const InvoiceItemOnDigitized: FC<IProps> = (props) => {
  const { document } = props;

  const dispatch = useDispatch();

  const handleDeleteInvoice = (): void => {
    dispatch(openBackofficeModal(new DeleteInvoiceModalData(document.id, false)));
  };

  return (
    <Grid item container xs={12}>
      <Grid container alignItems="center" xs={12} columnGap={1} padding="16px 0">
        <Grid item xs={3} marginLeft={4}>
          <Typography variant="body1" fontWeight="semiBold" noWrap>
            {document.filename}
          </Typography>
        </Grid>

        <HourGlassCell />
        <HourGlassCell />
        <HourGlassCell />

        <Grid item xs={2} marginLeft={8}>
          <StatusBadge
            status={FileStatusEnum.DIGITIZED}
            label={<FormattedMessage id="payment_request_status_badge.being_digitized" />}
          />
        </Grid>
        <HourGlassCell />
      </Grid>

      <Grid item container wrap="nowrap" gap={1} padding="8px 16px 16px" direction="row-reverse">
        <Button
          variant="outlined"
          size="small"
          startIcon={<Trash />}
          label={<FormattedMessage id="label.delete" />}
          onClick={handleDeleteInvoice}
        />
      </Grid>
    </Grid>
  );
};

export default InvoiceItemOnDigitized;
