import { Nullable, SORT_ORDER_ENUM } from '@core-ui/types';
import { buildURL } from '@core-ui/url';
import { DELETE, GET } from 'src/api/oceanApi';
import { ROUTES } from 'src/app/consts/routes';
import { responseError } from 'src/app/sagas';
import { vehicleIdSelector } from 'src/app/selectors';
import { showNotification } from 'src/components/Notification/actions';
import { OrderWalletsFields, WalletOutputSchema } from 'src/generated';
import history from 'src/history';
import { BACKOFFICE_TABS } from 'src/pages/backoffice/consts';
import { closeBackofficeModal, toggleModalLoading } from 'src/pages/backoffice/Modals/actions';
import { IWalletsFiltersState } from 'src/pages/backoffice/Wallets/WalletsFilters/reducer';
import { getWalletsFilters } from 'src/pages/backoffice/Wallets/WalletsFilters/selector';
import { getPaginationPage, getSortAndOrder } from 'src/pages/backoffice/Wallets/WalletsList/selector';
import { Action } from 'redux-actions';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';

const LIMIT = 50;

interface SortAndOrderDesc {
  orderBy: Nullable<OrderWalletsFields>;
  order: SORT_ORDER_ENUM;
}

interface IFetchDocuments {
  page: number;
}

function* fetchDocuments({ page }: IFetchDocuments) {
  const filters: IWalletsFiltersState = yield select(getWalletsFilters);
  const boatId: number = yield select(vehicleIdSelector);
  const { orderBy, order }: SortAndOrderDesc = yield select(getSortAndOrder);

  const requestUrl = buildURL(
    '/wallets',
    {
      page,
      per_page: LIMIT,
      order_by: orderBy || undefined,
      is_desc: order === SORT_ORDER_ENUM.DESC,
      boat_id: boatId,
      name: filters.name,
      wallet_types: filters.types ? filters.types : undefined,
    },
    {
      arrayFormat: 'none',
    }
  );

  const documents: WalletOutputSchema[] = yield call(GET, requestUrl);

  return documents;
}

function* getWalletsList() {
  try {
    const documents: WalletOutputSchema[] = yield fetchDocuments({ page: 0 });

    yield put(actions.setPaginationPage(0));
    yield put(
      actions.setWalletsList({
        value: documents,
        hasData: Boolean(documents.length),
        isAllDataReceived: documents.length < LIMIT,
      })
    );
  } catch (e) {
    yield call(responseError, e);
    yield put(
      actions.setWalletsList({
        error: e as Error,
        hasData: false,
        isAllDataReceived: false,
      })
    );
  }
}

function* getChunkWalletList() {
  try {
    const page: number = yield select(getPaginationPage);
    const documents: WalletOutputSchema[] = yield fetchDocuments({ page: page + 1 });

    yield put(actions.setPaginationPage(page + 1));
    yield put(
      actions.setChunkWalletList({
        value: documents,
        isAllDataReceived: documents.length < LIMIT,
      })
    );
  } catch (e) {
    yield call(responseError, e);
  }
}

function* deleteWallet({ payload }: Action<actions.IDeleteWallet>) {
  try {
    yield put(toggleModalLoading());

    yield call(DELETE, `/wallets/${payload.id}`);

    yield all([
      put(closeBackofficeModal()),
      put(
        showNotification({
          variant: 'success',
          titleId: 'wallets.delete.success',
        })
      ),
    ]);

    if (payload.shouldRedirect) {
      const boatId: number = yield select(vehicleIdSelector);

      history.push(buildURL(`/${boatId}/${ROUTES.BACKOFFICE}/${BACKOFFICE_TABS.WALLETS}`));
    } else {
      yield put(actions.getWalletsList(null));
    }
  } catch (e) {
    yield all([
      put(toggleModalLoading()),
      put(
        showNotification({
          variant: 'error',
          titleId: 'wallets.delete.error',
        })
      ),
    ]);
  }
}

export default [
  takeLatest(actions.getWalletsList, getWalletsList),
  takeLatest(actions.deleteWallet, deleteWallet),
  takeLatest(actions.getChunkWalletList, getChunkWalletList),
];
