import Button from '@core-ui/button';
import { COLOURS } from '@core-ui/styles';
import Tabs, { ITab } from '@core-ui/tabs';
import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/material/styles';
import SvgIcon from '@mui/material/SvgIcon';
import { BACKEND_URL } from 'src/app/consts/env';
import SourceFileRoot from 'src/pages/backoffice/Requests/SingleRequest/components/ItemTools/tabs/SourceFileRoot';
import { QUOTE_TAB_ENUM } from 'src/pages/backoffice/Requests/SingleRequest/QuotesList/consts';
import { IPaymentRequestFile } from 'src/pages/backoffice/Requests/types';
import { DownloadSimple, HourglassMedium } from '@phosphor-icons/react';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

interface IProps {
  document: IPaymentRequestFile;
}

const downloadSourceFilesButtonStyles: SxProps<Theme> = {
  whiteSpace: 'nowrap',
  marginTop: 2,
};

const BeingDigitizedItemTools: FC<IProps> = (props) => {
  const { document } = props;

  const tabs: ITab[] = [
    {
      label: <FormattedMessage id="tabs.digitized_document" />,
      value: QUOTE_TAB_ENUM.DIGITIZED_DOCUMENT,
      disabled: true,
      icon: (
        <SvgIcon>
          <HourglassMedium />
        </SvgIcon>
      ),
      iconPosition: 'end',
    },
    {
      label: <FormattedMessage id="tabs.source_file" />,
      value: QUOTE_TAB_ENUM.SOURCE_FILE,
    },
  ];

  return (
    <Box width="100%" paddingTop={3} borderTop={`1px dashed ${COLOURS.BORDER.MAIN}`}>
      <Box height={40} marginBottom={5} bgcolor={COLOURS.BACKGROUND.DISABLED} borderRadius={1} padding="0 16px">
        <Tabs tabs={tabs} active={QUOTE_TAB_ENUM.SOURCE_FILE} />
      </Box>

      <SourceFileRoot document={document} />

      <Button
        sx={downloadSourceFilesButtonStyles}
        color="blue"
        variant="outlined"
        startIcon={<DownloadSimple />}
        label={<FormattedMessage id="download.all.source.files" />}
        href={`${BACKEND_URL}/files/documents/${document.document_id}`}
      />
    </Box>
  );
};

export default BeingDigitizedItemTools;
