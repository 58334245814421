import State from 'src/app/types/state';
import { REQUEST_DOCUMENTS_TYPE_ENUM } from 'src/pages/backoffice/Requests/types';
import { createSelector } from 'reselect';

const getDocuments = (state: State) => state.backoffice.requests.singleRequest.request.value?.documents;

const getHasQuotes = createSelector(
  [getDocuments],
  (documents) =>
    documents?.filter((document) => document.document_type === REQUEST_DOCUMENTS_TYPE_ENUM.QUOTE).length !== 0
);

export default createSelector([getHasQuotes], (hasQuotes) => ({
  hasQuotes,
}));
