import Autocomplete from '@core-ui/autocomplete';
import Button from '@core-ui/button';
import { useFilter } from '@core-ui/hooks';
import TextField from '@core-ui/text_field';
import Grid from '@mui/material/Grid';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { CreateEditRequestModalData } from 'src/pages/backoffice/Modals/command';
import { IRequestsFiltersState } from 'src/pages/backoffice/Requests/RequestFilters/reducer';
import { ListBullets, MagnifyingGlass, MapPin, PlusCircle, SquaresFour } from '@phosphor-icons/react';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { batch, useDispatch } from 'react-redux';
import { openBackofficeModal } from '../../Modals/actions';
import { getRequestsList } from '../RequestList/actions';
import { REQUEST_STATUS_OPTIONS } from '../types';
import { resetRequestFilters, setTextFilter, updateRequestsFiltersQuery } from './actions';
import selector from './selector';

const RequestFilter: FC = () => {
  const { filters, statuses } = useAppSelector(selector);
  const dispatch = useDispatch();

  const handleFilterChange = useFilter<IRequestsFiltersState>({
    getList: getRequestsList,
    setFilter: setTextFilter,
  });

  const handleOpenModal = () => dispatch(openBackofficeModal(new CreateEditRequestModalData()));

  const showMapClick = () => {
    handleFilterChange('showMap')(true);
    handleFilterChange('view')('table');
  };

  const handleResetRequestFilters = () => {
    batch(() => {
      dispatch(resetRequestFilters());
      dispatch(updateRequestsFiltersQuery());
    });
  };

  return (
    <Grid container wrap="nowrap" padding="24px 0" columnGap={3}>
      <Grid item minWidth="180px">
        <TextField
          fullWidth
          size="medium"
          label={<FormattedMessage id="filters.search" />}
          // TODO: тип параметра onChange в компоненте TextField не соответствует handleFilterChange
          // @ts-ignore
          onChange={handleFilterChange('name')}
          value={filters.name}
          rightIcon1={<MagnifyingGlass />}
        />
      </Grid>

      <Grid item xs>
        <Autocomplete
          limitTags={3}
          options={REQUEST_STATUS_OPTIONS}
          value={statuses}
          isObjectValue
          fullWidth
          label={<FormattedMessage id="label.status" />}
          onChange={handleFilterChange('status')}
          multiple
        />
      </Grid>

      <Grid item container justifyContent="flex-end" columnSpacing={1} xs>
        <Grid item>
          <Button
            color="blue"
            variant={filters.showMap ? 'contained' : 'outlined'}
            size="large"
            onClick={showMapClick}
            icon={<MapPin />}
          />
        </Grid>
        <Grid item>
          <Button
            color="blue"
            variant={filters.view === 'table' ? 'contained' : 'outlined'}
            size="large"
            onClick={() => handleFilterChange('view')('table')}
            icon={<ListBullets />}
          />
        </Grid>
        <Grid item>
          <Button
            color="blue"
            variant={filters.view === 'tile' ? 'contained' : 'outlined'}
            size="large"
            onClick={handleResetRequestFilters}
            icon={<SquaresFour />}
          />
        </Grid>
        <Grid item marginLeft={10}>
          <Button
            onClick={handleOpenModal}
            variant="contained"
            color="blue"
            size="large"
            label="Add request"
            startIcon={<PlusCircle />}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RequestFilter;
