import State from 'src/app/types/state';
import { toMutableArray } from 'src/app/utils/app';
import { IWalletsFiltersState } from 'src/pages/backoffice/Wallets/WalletsFilters/reducer';
import { createSelector } from 'reselect';

export const keys: Array<keyof IWalletsFiltersState> = ['name', 'types'];

export const getWalletsFilters = (state: State) => state.backoffice.wallets.filters;

export default createSelector([getWalletsFilters], (filters) => ({
  filters: {
    ...filters,
    types: toMutableArray(filters.types),
  },
}));
