import { exhaustiveGuard } from 'src/app/utils/app';
import StatusInfo, { StatusInfoColors } from 'src/components/StatusInfo';
import { CALCULATED_STATUS } from 'src/pages/backoffice/Requests/types';
import React, { FC } from 'react';

interface IProps {
  status: CALCULATED_STATUS;
}

const RequestStatusBadge: FC<IProps> = (props) => {
  const { status } = props;

  switch (status) {
    case CALCULATED_STATUS.REQUEST_AWAITING_APPROVAL:
    case CALCULATED_STATUS.AWAITING_PAYMENT:
    case CALCULATED_STATUS.COLLECTING_QUOTES:
    case CALCULATED_STATUS.AWAITING_INVOICE:
      return <StatusInfo color={StatusInfoColors.PENDING}>{status}</StatusInfo>;
    case CALCULATED_STATUS.REQUEST_APPROVED:
    case CALCULATED_STATUS.QUOTE_APPROVED:
      return <StatusInfo color={StatusInfoColors.APPROVED}>{status}</StatusInfo>;
    case CALCULATED_STATUS.QUOTE_BEING_DIGITIZED:
    case CALCULATED_STATUS.INVOICE_AWAITING_APPROVAL:
    case CALCULATED_STATUS.QUOTE_AWAITING_APPROVAL:
    case CALCULATED_STATUS.INVOICE_BEING_DIGITIZED:
    case CALCULATED_STATUS.PAYMENT_PENDING_APPROVAL:
      return <StatusInfo color={StatusInfoColors.APPROVAL}>{status}</StatusInfo>;
    case CALCULATED_STATUS.DONE:
      return <StatusInfo color={StatusInfoColors.DONE}>{status}</StatusInfo>;
    case CALCULATED_STATUS.PAID:
      return <StatusInfo color={StatusInfoColors.PRIMARY}>{status}</StatusInfo>;
    case CALCULATED_STATUS.PAID_DELIVERED:
    case CALCULATED_STATUS.DELIVERED:
      return <StatusInfo color={StatusInfoColors.DELIVERED}>{status}</StatusInfo>;
    case CALCULATED_STATUS.QUOTE_DECLINED:
    case CALCULATED_STATUS.REQUEST_DECLINED:
    case CALCULATED_STATUS.INVOICE_DECLINED:
    case CALCULATED_STATUS.REQUEST_DISPUTE:
    case CALCULATED_STATUS.QUOTE_ERROR:
    case CALCULATED_STATUS.INVOICE_ERROR:
    case CALCULATED_STATUS.NONE:
      return <StatusInfo color={StatusInfoColors.DANGER}>{status}</StatusInfo>;
    default:
      return exhaustiveGuard(status);
  }
};

export default RequestStatusBadge;
