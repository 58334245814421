import { COLOURS } from '@core-ui/styles';
import { SxProps, Theme } from '@mui/material/styles';

export default (): Record<string, SxProps<Theme>> => ({
  container: {
    padding: '16px !important',
    position: 'sticky',
    top: 0,
    zIndex: 2,
    backgroundColor: COLOURS.WHITE,
    borderBottom: `1px solid ${COLOURS.BORDER.MAIN} !important`,
  },
});
