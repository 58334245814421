import Button from '@core-ui/button';
import Loader from '@core-ui/loader';
import { COLOURS } from '@core-ui/styles';
import Switch from '@core-ui/switch';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { SxProps, Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { BACKEND_URL } from 'src/app/consts/env';
import { IRouteParams } from 'src/app/consts/routes';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { openBackofficeModal } from 'src/pages/backoffice/Modals/actions';
import { DeleteInvoiceModalData } from 'src/pages/backoffice/Modals/command';
import InvoicesMoreButton from 'src/pages/backoffice/Requests/SingleRequest/InvoicesList/components/InvoicesMoreButton';
import { updateQuoteStatus } from 'src/pages/backoffice/Requests/SingleRequest/QuotesList/actions';
import {
  IInvoice,
  INVOICE_CALCULATED_STATUS_ENUM,
  INVOICE_DOCUMENT_STATUS,
  QUOTE_STATUS_ENUM,
  REQUEST_DOCUMENTS_TYPE_ENUM,
} from 'src/pages/backoffice/Requests/types';
import { CheckCircle, PaperPlaneTilt, X } from '@phosphor-icons/react';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import SendForApprovalModalData from '../../../../../Modals/command/SendForApprovalModalData';
import SendToErpRequestInvoicesModalData from '../../../../../Modals/command/SendToErpRequestInvoicesModalData';
import { markAsPaid, updateInvoiceStatus } from '../../actions';
import selector from './selector';
import useStyles from './styles';

const switcherContainerStyle: SxProps<Theme> = {
  backgroundColor: COLOURS.WHITE,
  flex: '0 1 0 !important',
};

interface IProps {
  invoice: IInvoice;
}

const InvoiceButtons: FC<IProps> = (props) => {
  const { invoice } = props;

  const dispatch = useDispatch();
  const styles = useStyles();
  const { requestId } = useParams<IRouteParams>();
  const { isLoading, requestIsArchive, userId, userIdOfRequest } = useAppSelector((state) =>
    selector(state, { requestId })
  );

  const invoiceStatus = invoice.calculated_status;
  const isPaid = invoice.is_paid;

  const isReceipt = invoice.document_type === REQUEST_DOCUMENTS_TYPE_ENUM.RECEIPT;
  const isDocumentApproved = invoice.document_approve_status === INVOICE_DOCUMENT_STATUS.APPROVED;
  const showActionButtons =
    invoiceStatus === INVOICE_CALCULATED_STATUS_ENUM.AWAITING && userId === userIdOfRequest && !isReceipt;
  const showCancelButton =
    invoiceStatus === INVOICE_CALCULATED_STATUS_ENUM.APPROVED ||
    (invoiceStatus === INVOICE_CALCULATED_STATUS_ENUM.DECLINED && !requestIsArchive && !isReceipt);

  const xeroButtonClick = () => {
    dispatch(openBackofficeModal(new SendToErpRequestInvoicesModalData(Number(requestId), invoice.id)));
  };

  const handleApproveClick = () =>
    dispatch(
      updateInvoiceStatus({
        documentId: invoice.id,
        status: INVOICE_CALCULATED_STATUS_ENUM.APPROVED,
      })
    );

  const handleDeclineClick = () =>
    dispatch(
      updateInvoiceStatus({
        documentId: invoice.id,
        status: INVOICE_CALCULATED_STATUS_ENUM.DECLINED,
      })
    );

  const handlePaidChange = (_e: unknown, checked: boolean) => {
    dispatch(
      markAsPaid({
        documentId: invoice.id,
        status: checked,
      })
    );
  };

  const handleCancelClick = () => {
    dispatch(
      updateQuoteStatus({
        requestId,
        documentIds: [invoice.id],
        status: QUOTE_STATUS_ENUM.AWAITING,
      })
    );
  };

  const handleAskClick = () => {
    dispatch(openBackofficeModal(new SendForApprovalModalData(invoice.id)));
  };

  const handleDownloadInvoice = () => {
    window.open(`${BACKEND_URL}/document/${invoice.id}/csv`);
  };

  const handleDeleteInvoice = () => {
    dispatch(openBackofficeModal(new DeleteInvoiceModalData(invoice.id, true)));
  };

  return (
    <Grid item container wrap="nowrap" gap={1} padding="16px 16px 16px 42px">
      <Grid item container gap={3} xs={3} alignItems="flex-start">
        {isDocumentApproved && (
          <Button
            sx={{ whiteSpace: 'nowrap' }}
            color="black"
            variant="outlined"
            endIcon={<PaperPlaneTilt />}
            label={<FormattedMessage id="label.send_to_erp" />}
            onClick={xeroButtonClick}
          />
        )}
      </Grid>

      <Grid item xs={1} />
      <Grid item xs={1} />
      <Grid item xs={1} />
      <Grid item width="36px" />

      {invoice.calculated_status !== INVOICE_CALCULATED_STATUS_ENUM.DECLINED && !isReceipt && (
        <Grid
          item
          container
          xs={2}
          component={Paper}
          variant="outlined"
          gap={3}
          padding={2}
          wrap="nowrap"
          alignSelf="center"
          sx={switcherContainerStyle}
        >
          {isLoading ? (
            <Loader mode="circle" size={20} />
          ) : (
            <>
              <Typography variant="body1">
                <FormattedMessage id="request_detail.buttons.paid" />
              </Typography>

              <Switch size="medium" checked={isPaid} onChange={handlePaidChange} />
            </>
          )}
        </Grid>
      )}

      <Grid item container justifyContent="flex-end" alignItems="flex-start" gap={1} xs>
        {showActionButtons && (
          <>
            {!isLoading && (
              <Button
                color="blue"
                variant="contained"
                startIcon={<CheckCircle />}
                label={<FormattedMessage id="label.approve" />}
                onClick={handleApproveClick}
              />
            )}
            {!isLoading && (
              <Button
                variant="outlined"
                startIcon={<X />}
                label={<FormattedMessage id="label.decline" />}
                onClick={handleDeclineClick}
              />
            )}
          </>
        )}

        {showCancelButton && !isLoading && (
          <Button
            sx={styles.button}
            loading={isLoading}
            variant="outlined"
            label={
              <FormattedMessage
                id={
                  invoiceStatus === INVOICE_CALCULATED_STATUS_ENUM.APPROVED
                    ? 'request_detail.buttons.cancel_approve'
                    : 'request_detail.buttons.cancel_decline'
                }
              />
            }
            onClick={handleCancelClick}
          />
        )}

        {!isReceipt && !isLoading && (
          <Button
            sx={styles.button}
            variant="contained"
            color="blue"
            startIcon={<CheckCircle />}
            label={<FormattedMessage id="label.ask_for_approval" />}
            onClick={handleAskClick}
          />
        )}

        <InvoicesMoreButton onDownload={handleDownloadInvoice} onDelete={handleDeleteInvoice} />
      </Grid>
    </Grid>
  );
};

export default InvoiceButtons;
