import { COLOURS } from '@core-ui/styles';
import { SxProps, Theme } from '@mui/material/styles';

export default (): Record<string, SxProps<Theme>> => ({
  container: {
    paddingX: 6,
    position: 'sticky',
    top: 0,
    backgroundColor: COLOURS.HUE_COCONUT.HUE_16,
    zIndex: 2,
  },
});
