import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { getFirstLettersOfUser } from 'src/app/utils/app';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

interface IProps {
  first_name?: string;
  last_name?: string;
}

const RequestAuthor: FC<IProps> = (props) => {
  const { first_name, last_name } = props;

  return (
    <Grid container columnGap={1}>
      <Grid item component={Avatar} variant="rounded" style={{ width: 22, height: 22 }}>
        <Typography variant="caption" fontWeight="semiBold">
          {getFirstLettersOfUser(first_name, last_name)}
        </Typography>
      </Grid>

      <Grid item component={Typography} variant="body1">
        {!first_name && !last_name ? (
          <FormattedMessage id="label.unknown_employee" />
        ) : (
          `${first_name ?? ''} ${last_name ?? ''}`
        )}
      </Grid>
    </Grid>
  );
};

export default RequestAuthor;
