import { SxProps, Theme } from '@mui/material/styles';
import React, { FC, CSSProperties } from 'react';
import { FormattedMessage } from 'react-intl';
import { Virtuoso } from 'react-virtuoso';
import { Selector } from 'reselect';
import { COLOURS } from '@core-ui/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import State from 'src/app/types/state';
import { useAppSelector } from 'src/hooks/useAppSelector';
import {
  PADDING_BOTTOM,
  PADDING_TOP,
} from 'src/pages/backoffice/Requests/RequestList/TileViewRequests/components/CardContainer/styles';
import { IPaymentRequest } from 'src/pages/backoffice/Requests/types';

import CardContainer from '../CardContainer';
import RequestCard from '../RequestCard';

const virtuosoStyle: CSSProperties = { height: '100%', width: '100%' };

const countLabelStyle: SxProps<Theme> = {
  padding: '2px 6px',
  backgroundColor: COLOURS.BORDER.MAIN,
  borderRadius: 16,
};

const MARGIN_TOP = 16;

const makeStyles = (length: number): CSSProperties => ({
  // 25 - выcота title
  // 130 - выcота item
  height:
    length === 0 ? 'fit-content' : `min(${length * 130 + MARGIN_TOP + 25 + PADDING_TOP + PADDING_BOTTOM}px, 100%)`,
});

interface IProps {
  title: string;
  selector: Selector<State, IPaymentRequest[]>;
}

const Cards: FC<IProps> = (props) => {
  const { selector, title } = props;
  const items = useAppSelector(selector);

  const renderItem = (idx: number, item: IPaymentRequest) => (
    <Grid item marginTop={idx === 0 ? 0 : 2}>
      <RequestCard item={item} />
    </Grid>
  );

  return (
    <CardContainer style={makeStyles(items.length)}>
      <Grid container wrap="nowrap" alignItems="center" justifyContent="space-between">
        <Typography variant="h6" noWrap>
          {title}
        </Typography>

        <Typography variant="caption" sx={countLabelStyle}>
          {items.length}
        </Typography>
      </Grid>

      <Grid item container height="100%" marginTop={`${MARGIN_TOP}px`}>
        {items.length > 0 ? (
          <Virtuoso style={virtuosoStyle} data={items} overscan={5} itemContent={renderItem} />
        ) : (
          <Typography variant="body1" noWrap>
            <FormattedMessage id="request.no_request" />
          </Typography>
        )}
      </Grid>
    </CardContainer>
  );
};

export default Cards;
