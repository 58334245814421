import { exhaustiveGuard } from 'src/app/utils/app';
import React, { FC } from 'react';

import StatusInfo, { StatusInfoColors } from 'src/components/StatusInfo';
import { PAYMENT_REQUEST_APPROVE_STATUS } from 'src/pages/backoffice/Requests/types';

interface IProps {
  status: PAYMENT_REQUEST_APPROVE_STATUS;
}

const RequestApproveStatusBadge: FC<IProps> = (props) => {
  const { status } = props;

  switch (status) {
    case PAYMENT_REQUEST_APPROVE_STATUS.APPROVED:
      return <StatusInfo color={StatusInfoColors.SUCCESS}>approved</StatusInfo>;
    case PAYMENT_REQUEST_APPROVE_STATUS.AWAITING:
      return <StatusInfo color={StatusInfoColors.PENDING}>pending</StatusInfo>;
    case PAYMENT_REQUEST_APPROVE_STATUS.DECLINED:
      return <StatusInfo color={StatusInfoColors.DANGER}>declined</StatusInfo>;
    default:
      return exhaustiveGuard(status);
  }
};

export default RequestApproveStatusBadge;
