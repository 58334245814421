export enum DETAIL_REQUEST_TABS {
  ABOUT = 'about',
  QUOTES = 'quotes',
  INVOICES = 'invoices',
}

export const REQUEST_DETAIL_TABS_PATH = Object.values(DETAIL_REQUEST_TABS).join('|');

export enum QUOTES_COMPARE {
  COMPARE = 'compare',
  LIST = 'list',
}

export interface SingleRequestQueryParams {
  quotes_tab: QUOTES_COMPARE;
}

export interface IQuoteChecked {
  checked: boolean;
}

export const QUOTES_TAB_NAME = 'quotes_tab';
