import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { COLOURS } from '@core-ui/styles';
import Amount from 'src/components/Amount';
import {
  heightContainerBaseInfo,
  heightContainerMarker,
  widthContainerLeftSidebar,
} from 'src/pages/backoffice/Requests/SingleRequest/consts';
import selector from './selector';
import useStyles from './styles';

interface IProps {}

const LeftSidebar: FC<IProps> = () => {
  const { articles, averageHeight, averageTotal, item } = useAppSelector(selector);

  const styles = useStyles({
    averageHeight,
  });

  return (
    <Grid container width={widthContainerLeftSidebar}>
      <Grid
        item
        height={heightContainerMarker}
        width="100%"
        borderBottom={`1px solid ${COLOURS.BORDER.MAIN}`}
        sx={styles.head}
      >
        <Grid sx={styles.quoteContainerMarker}>
          <Box textAlign="right" paddingRight={2} sx={styles.marker}>
            <Typography variant="body2" color="textSecondary" paddingTop={2}>
              <FormattedMessage id="quotes.market_average" />
            </Typography>

            <Amount amount={averageTotal} currencyId={item?.amount_currency_id} />
          </Box>
        </Grid>
      </Grid>

      <Grid height={heightContainerBaseInfo} width="100%" borderBottom={`1px solid ${COLOURS.BORDER.MAIN}`}>
        <Typography paddingLeft={2} paddingTop={2}>
          <FormattedMessage id="quotes.vendor" />
        </Typography>

        <Box paddingLeft={2} paddingTop={12}>
          <Typography>
            <FormattedMessage id="quotes.price_eur" />
          </Typography>

          <Typography color="textSecondary" paddingTop={3}>
            <FormattedMessage id="quotes.vat" />
          </Typography>
        </Box>
      </Grid>

      <Grid width="100%" borderBottom={`1px solid ${COLOURS.BORDER.MAIN}`}>
        {articles.map((article) => (
          <Grid key={article.cost_article} container wrap="nowrap">
            <Grid>
              <Typography sx={styles.costArticle} paddingLeft={2} paddingTop={4} paddingBottom={4}>
                {article.cost_article}
              </Typography>
            </Grid>

            <Grid bgcolor={article.color} width={16} height={16} alignSelf="center" marginLeft={4} borderRadius={1} />
          </Grid>
        ))}
      </Grid>

      <Grid width="100%">
        <Box paddingLeft={2}>
          <Typography paddingTop={4} paddingBottom={4}>
            <FormattedMessage id="quotes.due_date" />
          </Typography>

          <Typography paddingTop={4} paddingBottom={4}>
            <FormattedMessage id="request_detail.type" />
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default LeftSidebar;
