import State from 'src/app/types/state';
import { createSelector } from 'reselect';

const getCurrentUserSelector = (state: State) => state.app.user;
const getLoading = (state: State, props: { requestId: number }) =>
  state.backoffice.requests.singleRequest.request.loadingItems?.[props.requestId];

export default createSelector([getCurrentUserSelector, getLoading], (user, loading) => ({
  user,
  loading,
}));
