import { COLOURS } from '@core-ui/styles/consts';
import { SxProps, Theme } from '@mui/material/styles';
import { heightPercent, maxHeightQuoteBar } from 'src/pages/backoffice/Requests/SingleRequest/consts';

interface IProps {
  averageHeight: number;
}

export default ({ averageHeight }: IProps): Record<string, SxProps<Theme>> => ({
  head: { background: `${COLOURS.BACKGROUND.SECONDARY}`, position: 'relative' },
  quoteContainerMarker: { position: 'absolute', height: maxHeightQuoteBar, width: '100%', bottom: 0 },
  marker: {
    position: 'absolute',
    bottom: averageHeight === heightPercent ? maxHeightQuoteBar : `${averageHeight}%`,
    left: '50%',
  },
  costArticle: { width: '120px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' },
});
