import { extractFlattenPaths, Route } from '@core-ui/redux-router';
import { ROUTES } from 'src/app/consts/routes';
import { getClientCostArticleDict } from 'src/dictionary/actions';
import { BACKOFFICE_TABS } from 'src/pages/backoffice/consts';
import { IBackofficeState } from 'src/pages/backoffice/reducer';
import {
  resetSingleWalletFilters,
  setSingleWalletFiltersFromQuery,
  updateSingleWalletFiltersQuery,
} from 'src/pages/backoffice/Wallets/SingleWalletFilters/actions';
import { getSingleWalletList, resetSingleWalletList } from 'src/pages/backoffice/Wallets/SingleWalletList/actions';
import SingleWalletRoot from 'src/pages/backoffice/Wallets/SingleWalletRoot';
import { getWallet } from 'src/pages/backoffice/Wallets/SingleWalletRoot/actions';
import {
  resetWalletsFilters,
  setWalletsFiltersFromQuery,
  updateWalletsFiltersQuery,
} from 'src/pages/backoffice/Wallets/WalletsFilters/actions';
import { getWalletsList, resetWalletsList } from 'src/pages/backoffice/Wallets/WalletsList/actions';
import WalletsRoot from 'src/pages/backoffice/Wallets/WalletsRoot';

const key: keyof IBackofficeState = 'wallets';

const routes: Route[] = [
  {
    key,
    exact: true,
    path: `/:boatId([0-9]+)/:route(${ROUTES.BACKOFFICE})/:backofficeTab(${BACKOFFICE_TABS.WALLETS})`,
    component: WalletsRoot,
    effects: [
      { events: ['on_enter'], trigger: resetWalletsList },
      { events: ['on_enter'], trigger: resetWalletsFilters },
      { events: ['on_enter'], trigger: setWalletsFiltersFromQuery },
      { events: ['on_enter'], trigger: updateWalletsFiltersQuery },
      { events: ['on_enter'], trigger: getWalletsList },
      { events: ['once'], trigger: getClientCostArticleDict },
    ],
  },
  {
    key,
    exact: true,
    path: `/:boatId([0-9]+)/:route(${ROUTES.BACKOFFICE})/:backofficeTab(${BACKOFFICE_TABS.WALLETS})/:walletId`,
    component: SingleWalletRoot,
    effects: [
      { events: ['on_enter'], trigger: resetSingleWalletList },
      { events: ['on_enter'], trigger: resetSingleWalletFilters },
      { events: ['on_enter'], trigger: setSingleWalletFiltersFromQuery },
      { events: ['on_enter'], trigger: updateSingleWalletFiltersQuery },
      { events: ['on_enter'], trigger: getWallet },
      { events: ['on_enter'], trigger: getSingleWalletList },
      { events: ['once'], trigger: getClientCostArticleDict },
    ],
  },
];

export const WALLETS_PATHS = extractFlattenPaths(routes);

export default routes;
