import { Nullable } from '@core-ui/types';
import { GET, POST } from 'src/api/oceanApi';
import { responseError } from 'src/app/sagas';
import { vehicleIdSelector } from 'src/app/selectors';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { IOpenLayerFeature, IPaymentRequest } from '../types';
import * as actions from './actions';

function* getRequestList() {
  const boatId: Nullable<number> = yield select(vehicleIdSelector);

  try {
    // todo: нужно сделать пагинацию
    const response: IPaymentRequest[] = yield call(GET, 'requests', { boat_id: boatId, per_page: 1000 });

    yield put(
      actions.setRequestsList({
        value: response,
        hasData: !!response.length,
        isAllDataReceived: true,
      })
    );
  } catch (e) {
    yield call(responseError, e);
    yield put(actions.setRequestsList({ error: e as Error, hasData: false, isAllDataReceived: false }));
  }
}

function* getFeaturesList() {
  const boatId: Nullable<number> = yield select(vehicleIdSelector);

  try {
    const response: IOpenLayerFeature[] = yield call(POST, 'requests/get_map_points', {
      request_ids: [],
      boat_id: boatId,
    });

    yield put(
      actions.setFeaturesList({
        value: response,
        hasData: !!response.length,
        isAllDataReceived: true,
      })
    );
  } catch (e) {
    yield call(responseError, e);
    yield put(actions.setFeaturesList({ error: e as Error, hasData: false, isAllDataReceived: false }));
  }
}

export default [
  takeLatest(actions.getRequestsList, getRequestList),
  takeLatest(actions.getFeaturesList, getFeaturesList),
];
