import { HeaderCell, TableRow } from '@core-ui/table';
import { SORT_ORDER_ENUM } from '@core-ui/types';
import Box from '@mui/material/Box';
import TableHead from '@mui/material/TableHead';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { getSingleWalletList, setOrder, setOrderBy } from 'src/pages/backoffice/Wallets/SingleWalletList/actions';
import { getSingleWalletSortOrder } from 'src/pages/backoffice/Wallets/SingleWalletList/selector';
import React, { FC } from 'react';
import { batch, useDispatch } from 'react-redux';
import { COMPACT_HEADER_HEIGHT, FILTERS_HEIGHT, PADDING_Y } from 'src/pages/backoffice/consts';
import { HEADERS } from './consts';
import useStyles from './styles';

const TableHeader: FC = () => {
  const { orderBy, order } = useAppSelector(getSingleWalletSortOrder);

  const styles = useStyles();
  const dispatch = useDispatch();

  const handleChangeSort = (value?: string) => () => {
    if (!value) {
      return;
    }

    batch(() => {
      if (orderBy !== value) {
        dispatch(setOrder(SORT_ORDER_ENUM.DESC));
      } else {
        dispatch(setOrder(order === SORT_ORDER_ENUM.ASC ? SORT_ORDER_ENUM.DESC : SORT_ORDER_ENUM.ASC));
      }

      dispatch(setOrderBy(value));
      dispatch(getSingleWalletList(null));
    });
  };

  return (
    <TableHead sx={styles.head}>
      <TableRow>
        {HEADERS.map((column) => (
          <HeaderCell
            key={column.key}
            align={column.align}
            right={column.right}
            shadow={column.shadow}
            sticky
            isActive={orderBy === column.orderBy}
            onChangeSort={column.orderBy ? handleChangeSort(column.orderBy) : undefined}
            order={order}
            sx={{
              top: COMPACT_HEADER_HEIGHT + FILTERS_HEIGHT - PADDING_Y,
              minWidth: column.width,
              width: column.width,
              maxWidth: column.width,
            }}
          >
            <Box>{column.title}</Box>
          </HeaderCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
