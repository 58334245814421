import { HEIGHT_SIZE } from '@core-ui/styles';

export enum BACKOFFICE_TABS {
  INBOX = 'inbox',
  WALLETS = 'wallets',
  CONTRACTS = 'contracts',
  REQUESTS = 'requests',
  LEDGER = 'ledger',
}

export const BACKOFFICE_PAGES_PATH = Object.values(BACKOFFICE_TABS).join('|');

export const PLACEHOLDER_POSITION_ADJUSTMENT = 22;
export const DEFAULT_TABLE_AUTOCOMPLETE_WIDTH = 274;
export const LARGE_HEADER_HEIGHT = 144;
export const COMPACT_HEADER_HEIGHT = 72;
export const PADDING_Y = 24;
export const FILTERS_HEIGHT = 96;

// determines the scroll position at which the header component will be changed to small header
export const INTERSECTION_OFFSET = -80;
export const INTERSECTION_ROOT_MARGIN =
  HEIGHT_SIZE.headerHeight + HEIGHT_SIZE.tabsHeight + LARGE_HEADER_HEIGHT + INTERSECTION_OFFSET;
