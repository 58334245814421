import { TableCell, TableRow } from '@core-ui/table';
import { Nullable } from '@core-ui/types';
import { buildURL } from '@core-ui/url';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { DATE_FORMAT } from 'src/app/consts/app';
import { ROUTES } from 'src/app/consts/routes';
import { AmountFormatter, CurrencyCodeGetterById } from 'src/app/types/common';
import { formatTime } from 'src/app/utils/dates';
import StatusBadge from 'src/components/StatusBadge';
import { FileStatusEnum, WalletRecordSchema } from 'src/generated';
import history from 'src/history';
import { BACKOFFICE_TABS } from 'src/pages/backoffice/consts';
import { LEDGER_DOCUMENT_PREVIOUS_SECTION_ENUM } from 'src/pages/backoffice/Ledger/consts';
import { LedgerDocumentQueryParams } from 'src/pages/backoffice/Ledger/types';
import SingleWalletRowMoreButton from 'src/pages/backoffice/Wallets/SingleWalletList/SingleWalletRowMoreButton';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import IconButtonWithTooltip from 'src/components/IconButtonWithTooltip';
import useStyles from './styles';

interface IProps {
  item: WalletRecordSchema;
  boatId: Nullable<number>;
  walletId: number;
  amountFormatter: AmountFormatter;
  currencyCodeGetter: CurrencyCodeGetterById;
}

const SingleWalletRow: FC<IProps> = (props) => {
  const { item, boatId, walletId, amountFormatter, currencyCodeGetter } = props;
  const styles = useStyles();

  const handleOpenItem = () => {
    if (isDocumentDigitized && item.document_id) {
      history.push(
        buildURL<LedgerDocumentQueryParams>(
          `/${boatId}/${ROUTES.BACKOFFICE}/${BACKOFFICE_TABS.LEDGER}/${item.document_id}`,
          {
            previous_section: LEDGER_DOCUMENT_PREVIOUS_SECTION_ENUM.WALLETS,
            previous_id: String(walletId),
          }
        )
      );
    }
  };

  const createdAt = formatTime(item.created_at, { dateFormat: DATE_FORMAT });

  const uploadedByName = [item.uploaded_by_first_name, item.uploaded_by_last_name].filter(Boolean).join(' ') || (
    <FormattedMessage id="label.no_info" />
  );

  const isDocumentDigitized = item.status === FileStatusEnum.DIGITIZED;

  const reasonOfReturn =
    item.status === FileStatusEnum.RETURNED && item.reason_of_return
      ? `${item.status}: ${item.reason_of_return}`.replace(/_/g, ' ')
      : `${item.status}`.replace(/_/g, ' ');

  const currency = item.currency_id ? currencyCodeGetter(Number(item.currency_id)) : '';

  return (
    <TableRow>
      <TableCell onClick={handleOpenItem} sx={styles.nameCell}>
        {item.filename}
      </TableCell>

      <TableCell sx={styles.breakWordCell}>{item.vendor}</TableCell>

      <TableCell>{createdAt}</TableCell>

      <TableCell>
        <StatusBadge status={item.status} label={reasonOfReturn} />
      </TableCell>

      <TableCell sx={styles.breakWordCell}>{uploadedByName}</TableCell>

      <TableCell>
        <Typography fontFamily="mono">{currency}</Typography>
      </TableCell>

      <TableCell align="right">
        <Typography fontFamily="mono" fontWeight="semiBold">
          {amountFormatter(item.total)}
        </Typography>
      </TableCell>

      <TableCell padding="medium">
        <Grid container gap={2} justifyContent="flex-start" alignItems="center">
          <SingleWalletRowMoreButton item={item} />

          {isDocumentDigitized && <IconButtonWithTooltip onClick={handleOpenItem} tooltipLabel="label.open_document" />}
        </Grid>
      </TableCell>
    </TableRow>
  );
};

export default SingleWalletRow;
