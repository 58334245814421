import { createSelector } from 'reselect';

import { getFilteredGroupedStatusList } from 'src/pages/backoffice/Requests/statusSelectors';
import State from 'src/app/types/state';

const getListState = (state: State) => state.backoffice.requests.list;

export default createSelector([getListState, getFilteredGroupedStatusList], (listState, groupedStatusList) => ({
  hasData: listState.hasData,
  groupedStatusList,
}));
