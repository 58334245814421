import { COLOURS } from '@core-ui/styles';
import { buildURL } from '@core-ui/url';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import { ROUTES } from 'src/app/consts/routes';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { BACKOFFICE_TABS } from 'src/pages/backoffice/consts';
import RequestStatusBadge from 'src/pages/backoffice/Requests/RequestList/components/RequestStatusBadge';
import { DETAIL_REQUEST_TABS } from 'src/pages/backoffice/Requests/SingleRequest/types';
import { IPaymentRequest, PAYMENT_REQUEST_TYPE_ENUM } from 'src/pages/backoffice/Requests/types';
import { ChatCircleDots, File, Lightning } from '@phosphor-icons/react';
import React, { FC, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import selector from './selector';
import useStyles from './styles';

interface IProps {
  item: IPaymentRequest;
}

const RequestCard: FC<IProps> = (props) => {
  const { item } = props;

  const memoizedSelector = useMemo(selector, []);
  const { boatId, employee } = useAppSelector((state) => memoizedSelector(state, { employeeId: item.employee_id }));

  const classes = useStyles();

  const uploadedByName = [item.employee_first_name, item.employee_last_name].filter(Boolean).join(' ') || (
    <FormattedMessage id="label.no_info" />
  );

  return (
    <Link
      to={buildURL(
        `/${boatId}/${ROUTES.BACKOFFICE}/${BACKOFFICE_TABS.REQUESTS}/${item.id}/${DETAIL_REQUEST_TABS.ABOUT}`
      )}
    >
      <Paper elevation={0} className={classes.paper}>
        <Grid container justifyContent="space-between" wrap="nowrap" columnGap={1}>
          <RequestStatusBadge status={item.calculated_status} />

          {item.request_type === PAYMENT_REQUEST_TYPE_ENUM.EMERGENCY && (
            <>
              <Typography
                variant="body2"
                color={COLOURS.HUE_STRAWBERRY.HUE_100}
                fontWeight="semiBold"
                marginLeft="auto"
              >
                Emergency
              </Typography>

              <SvgIcon htmlColor={COLOURS.HUE_STRAWBERRY.HUE_100} fontSize="small">
                <Lightning />
              </SvgIcon>
            </>
          )}
        </Grid>

        <Typography variant="subtitle2" noWrap marginTop={2}>
          {item.name}
        </Typography>

        <Grid item container marginTop="30px" alignItems="center" wrap="nowrap" columnGap={1}>
          <Grid item>
            <Avatar variant="rounded" sx={{ width: 18, height: 18 }} src={`${employee?.avatar_id}`} />
          </Grid>

          <Grid item component={Typography} variant="body2" color="text.secondary" zeroMinWidth noWrap>
            {uploadedByName}
          </Grid>

          <Grid item container columnGap={3} wrap="nowrap" justifyContent="flex-end" width="auto">
            <Grid item container alignItems="center" columnGap={1} wrap="nowrap">
              <SvgIcon htmlColor={COLOURS.HUE_COCONUT.HUE_100} fontSize="small">
                <ChatCircleDots />
              </SvgIcon>

              <Typography variant="body2" color={COLOURS.HUE_COCONUT.HUE_100}>
                {item.comments?.length || 0}
              </Typography>
            </Grid>

            <Grid item container alignItems="center" columnGap={1} wrap="nowrap">
              <SvgIcon htmlColor={COLOURS.HUE_COCONUT.HUE_100} fontSize="small">
                <File />
              </SvgIcon>

              <Typography variant="body2" color={COLOURS.HUE_COCONUT.HUE_100}>
                {item.files_number}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Link>
  );
};

export default RequestCard;
