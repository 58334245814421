import { combineReducers } from 'redux';
import invoices, { IInvoicesListState } from './InvoicesList/reducer';
import compareQuote, { ICompareQuoteState } from './QuotesCompare/reducer';
import request, { IPaymentRequestState } from './QuotesList/reducer';

export interface ISingleRequestState {
  request: IPaymentRequestState;
  compareQuote: ICompareQuoteState;
  invoices: IInvoicesListState;
}

export default combineReducers({
  request,
  compareQuote,
  invoices,
});
