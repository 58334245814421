import { COLOURS } from '@core-ui/styles/consts';
import { SxProps, Theme } from '@mui/material/styles';

interface IProps {
  active: boolean;
}

export default ({ active }: IProps): Record<string, SxProps<Theme>> => ({
  barActive: {
    background: active ? `${COLOURS.BACKGROUND.MAIN}` : 'none',
    cursor: 'pointer',
  },
});
