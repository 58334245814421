import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Amount from 'src/components/Amount';
import CurrencyCode from 'src/components/CurrencyCode';
import { IInvoice, IQuote } from 'src/pages/backoffice/Requests/types';
import { FC } from 'react';
import { columns } from './consts';

interface IProps {
  document: IQuote | IInvoice;
}

const DigitizedDocumentRoot: FC<IProps> = (props) => {
  const { document } = props;

  return (
    <Table>
      <TableHead>
        <TableRow>
          {columns.map((column) => (
            <TableCell key={column.key} align={column.align}>
              {column.title}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>

      <TableBody>
        {document.lines.map((line) => (
          <TableRow hover key={line.id}>
            <TableCell>{line.title}</TableCell>

            <TableCell />
            {/* @ts-ignore */}
            <TableCell padding="noneVertical">
              <Typography variant="body1" color="text.primary">
                {line.cost_article}
              </Typography>

              <Typography variant="body2" color="text.secondary" marginTop={1}>
                {line.cost_center}
              </Typography>
            </TableCell>

            <TableCell align="right">{line.qty}</TableCell>

            <TableCell align="right">{line.vat_percent ? `${line.vat_percent}%` : line.vat}</TableCell>

            <TableCell align="right">
              <CurrencyCode currencyId={line.total_price_currency_id} />
            </TableCell>

            <TableCell align="right">
              <Typography variant="body1" color="text.primary" fontWeight="semiBold">
                <Amount amount={line.subtotal_price} currencyId={line.total_price_currency_id} />
              </Typography>
            </TableCell>

            <TableCell align="right">
              <Typography variant="body1" color="text.primary" fontWeight="semiBold">
                <Amount amount={line.total_price} currencyId={line.total_price_currency_id} />
              </Typography>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>

      <TableFooter>
        <TableRow hover>
          <TableCell>
            <Typography variant="body1" color="text.primary" fontWeight="semiBold">
              Total
            </Typography>
          </TableCell>

          <TableCell />
          <TableCell />
          <TableCell />

          <TableCell align="right">
            <Typography variant="body1" color="text.primary" fontWeight="semiBold">
              {document.vat}
            </Typography>
          </TableCell>

          <TableCell align="right">
            <Typography variant="body1" color="text.primary" fontWeight="semiBold">
              <CurrencyCode currencyId={document.total_price_currency_id} />
            </Typography>
          </TableCell>

          <TableCell align="right">
            <Typography variant="body1" color="text.primary" fontWeight="semiBold">
              <Amount amount={document.subtotal} currencyId={document.total_price_currency_id} />
            </Typography>
          </TableCell>

          <TableCell align="right">
            <Typography variant="body1" color="text.primary" fontWeight="semiBold">
              <Amount amount={document.total_price} currencyId={document.total_price_currency_id} />
            </Typography>
          </TableCell>
        </TableRow>
      </TableFooter>
    </Table>
  );
};

export default DigitizedDocumentRoot;
