import Immutable from '@core-ui/immutable';
import reducer from '@core-ui/reducers_handlers';
import { IOption, Nullable } from '@core-ui/types';
import * as actions from 'src/pages/backoffice/Requests/RequestFilters/actions';
import { CALCULATED_STATUS } from 'src/pages/backoffice/Requests/types';
import { handleActions } from 'redux-actions';

export type IFiltersView = 'tile' | 'table';

export interface IRequestsFiltersState {
  view: IFiltersView;
  showMap: boolean;
  name: string;
  status: Nullable<IOption<CALCULATED_STATUS, CALCULATED_STATUS>[]>;
  selectedFeatures: number[];
}

const IRequestFilterState = Immutable<IRequestsFiltersState>({
  view: 'tile',
  showMap: false,
  name: '',
  status: null,
  selectedFeatures: [],
});

export default handleActions({}, reducer<typeof IRequestFilterState>(actions.reducerName, IRequestFilterState));
