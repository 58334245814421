import singleWalletList, { ISingleWalletListState } from 'src/pages/backoffice/Wallets/SingleWalletList/reducer';
import singleWalletDragNDrop, {
  ISingleWalletDragNDropState,
} from 'src/pages/backoffice/Wallets/SingleWalletDragNDrop/reducer';
import singleWalletFilters, {
  ISingleWalletFiltersState,
} from 'src/pages/backoffice/Wallets/SingleWalletFilters/reducer';
import singleWallet, { ISingleWalletState } from 'src/pages/backoffice/Wallets/SingleWalletRoot/reducer';
import filters, { IWalletsFiltersState } from 'src/pages/backoffice/Wallets/WalletsFilters/reducer';
import list, { IWalletsListState } from 'src/pages/backoffice/Wallets/WalletsList/reducer';
import { combineReducers } from 'redux';

export interface IWalletsState {
  list: IWalletsListState;
  filters: IWalletsFiltersState;
  singleWallet: ISingleWalletState;
  singleWalletList: ISingleWalletListState;
  singleWalletFilters: ISingleWalletFiltersState;
  singleWalletDragNDrop: ISingleWalletDragNDropState;
}

export default combineReducers({
  list,
  filters,
  singleWallet,
  singleWalletList,
  singleWalletFilters,
  singleWalletDragNDrop,
});
