import State from 'src/app/types/state';
import { createSelector } from 'reselect';

const getLoadingButton = (state: State, props: { requestId: string }) =>
  state.backoffice.requests.singleRequest.request.loadingItems?.[props.requestId];
const getRequestStatusIsArchive = (state: State) => state.backoffice.requests.singleRequest.request.value?.is_archive;

export default createSelector([getLoadingButton, getRequestStatusIsArchive], (isLoading, requestIsArchive) => ({
  isLoading,
  requestIsArchive,
}));
