import { FileStatusEnum } from 'src/generated';
import { createAction } from 'redux-actions';

export const reducerName = 'wallets.singleWallet.row';

export interface IUpdateErpCategory {
  documentId: number;
  categoryId: string;
}

export const updateErpCategory = createAction<IUpdateErpCategory>(`${reducerName}.UPDATE_ERP_CATEGORY`);

export interface IDeleteWalletTransaction {
  id: number;
  status: FileStatusEnum;
}

export const deleteWalletTransaction = createAction<IDeleteWalletTransaction>(
  `${reducerName}.DELETE_SINGLE_WALLET_DOCUMENT`
);
