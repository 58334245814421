import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles/createTheme';

export default makeStyles(
  (theme: Theme) => ({
    row: {
      borderRadius: 2,
      '&:nth-child(even)': {
        backgroundColor: 'rgba(239, 241, 243, 0.5)',
      },
    },
    name: {
      textDecoration: 'underline',
      color: theme.palette.text.primary,
    },
  }),
  { name: 'TableRow' }
);
