import { IQuote, IQuoteLine } from 'src/pages/backoffice/Requests/types';

export const getBars = (quote: IQuote) =>
  quote.lines.reduce<IQuoteLine[]>((acc, line) => {
    let index: number;
    const nameBar = acc.find((item, idx) => {
      index = idx;

      return item.cost_article === line.cost_article;
    });

    if (nameBar?.cost_article === line.cost_article) {
      // @ts-ignore
      acc[index] = { ...line, total_price: line.total_price + acc[index]?.total_price };
    } else {
      acc.push(line);
    }

    return acc;
  }, []);
