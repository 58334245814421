import Grid from '@mui/material/Grid';
import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setCompareView } from './actions';
import LeftSidebar from './components/LeftSidebar';
import QuoteButtons from './components/QuoteButtons';
import QuotesColumns from './components/QuotesColumns';

interface IProps {}

const QuotesCompare: FC<IProps> = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCompareView());
  }, []);

  return (
    <>
      <QuoteButtons />

      <Grid container wrap="nowrap">
        <LeftSidebar />

        <QuotesColumns />
      </Grid>
    </>
  );
};

export default QuotesCompare;
