import filters, { IRequestsFiltersState } from 'src/pages/backoffice/Requests/RequestFilters/reducer';
import { featuresList, IFeaturesList, IRequestList, list } from 'src/pages/backoffice/Requests/RequestList/reducer';
import singleRequest, { ISingleRequestState } from 'src/pages/backoffice/Requests/SingleRequest/reducer';
import { combineReducers } from 'redux';

export interface IRequestsState {
  list: IRequestList;
  featuresList: IFeaturesList;
  filters: IRequestsFiltersState;
  singleRequest: ISingleRequestState;
}

export default combineReducers({
  list,
  featuresList,
  filters,
  singleRequest,
});
