import Container from '@mui/material/Container';
import SingleWalletDragNDrop from 'src/pages/backoffice/Wallets/SingleWalletDragNDrop';
import SingleWalletFilters from 'src/pages/backoffice/Wallets/SingleWalletFilters';
import SingleWalletHeader from 'src/pages/backoffice/Wallets/SingleWalletHeader';
import SingleWalletList from 'src/pages/backoffice/Wallets/SingleWalletList';
import React, { useEffect, useRef, useState } from 'react';
import NoDataContainer from 'src/components/NoDataContainer';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { INTERSECTION_ROOT_MARGIN } from 'src/pages/backoffice/consts';
import SingleWalletHeaderSmall from 'src/pages/backoffice/Wallets/SingleWalletHeaderSmall';
import { SingleWalletRootSkeleton } from 'src/pages/backoffice/Wallets/skeletons';
import { getNoDataContainerData, getWalletDataAvailable } from './selector';
import useStyles from './styles';

const SingleWalletRoot = () => {
  const walletDataAvailable = useAppSelector(getWalletDataAvailable);
  const styles = useStyles();

  const [dropzoneIntersecting, setDropzoneIntersecting] = useState(true);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setDropzoneIntersecting(entry.isIntersecting);
      },
      { rootMargin: `-${INTERSECTION_ROOT_MARGIN}px` }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => observer.disconnect();
  }, [dropzoneIntersecting, walletDataAvailable]);

  return (
    <Container maxWidth="xl" sx={styles.container}>
      <NoDataContainer
        selector={getNoDataContainerData}
        loader={<SingleWalletRootSkeleton />}
        sx={styles.innerContainer}
      >
        {dropzoneIntersecting ? <SingleWalletHeader /> : <SingleWalletHeaderSmall />}

        <SingleWalletDragNDrop ref={ref} />
        <SingleWalletFilters />

        <SingleWalletList />
      </NoDataContainer>
    </Container>
  );
};

export default SingleWalletRoot;
