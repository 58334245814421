import { asMutable } from '@core-ui/immutable';
import State from 'src/app/types/state';
import { IRequestsFiltersState } from 'src/pages/backoffice/Requests/RequestFilters/reducer';
import { DEFAULT_REQUEST_STATUS_OPTIONS, REQUEST_STATUS_OPTIONS } from 'src/pages/backoffice/Requests/types';
import { createSelector } from 'reselect';

export const keys: Array<keyof IRequestsFiltersState> = ['view', 'name'];

export const getFilters = (state: State) => state.backoffice.requests.filters;

export const getFiltersView = (state: State) => state.backoffice.requests.filters.view;

export const getSelectedStatuses = createSelector([getFilters], (filters) =>
  asMutable(filters.status || (filters.view === 'tile' ? DEFAULT_REQUEST_STATUS_OPTIONS : REQUEST_STATUS_OPTIONS), {
    deep: true,
  })
);

export default createSelector([getFilters, getSelectedStatuses], (filters, statuses) => ({
  filters,
  statuses,
}));
