import { handleActions } from 'redux-actions';
import Immutable from '@core-ui/immutable';
import reducer, { List } from '@core-ui/reducers_handlers';
import { IOpenLayerFeature, IPaymentRequest } from 'src/pages/backoffice/Requests/types';

import * as requestListActions from './actions';

export type IRequestList = List<IPaymentRequest>;

const IRequestListState = Immutable<IRequestList>({
  loaded: false,
  hasData: false,
  loading: false,
  chunkLoading: false,
  isAllDataReceived: false,
});

export const list = handleActions<typeof IRequestListState, unknown>(
  {},
  reducer<typeof IRequestListState>(requestListActions.reducerName, IRequestListState)
);

export type IFeaturesList = List<IOpenLayerFeature>;

const IFeaturesListState = Immutable<IFeaturesList>({
  loaded: false,
  hasData: false,
  loading: false,
  chunkLoading: false,
  isAllDataReceived: false,
});

export const featuresList = handleActions<typeof IFeaturesListState, unknown>(
  {},
  reducer<typeof IFeaturesListState>(requestListActions.featuresListReducerName, IFeaturesListState)
);
