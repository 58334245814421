import { ScrollMemory } from '@core-ui/redux-router';
import Grid from '@mui/material/Grid';
import { SxProps, Theme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import ChunkLoader from 'src/components/ChunkLoader';
import NoDataContainer from 'src/components/NoDataContainer';
import NoFiltratedDataPlaceholder from 'src/components/NoFiltratedDataPlaceholder';
import TableSkeleton from 'src/components/TableSkeleton';
import { useAppSelector } from 'src/hooks/useAppSelector';
import NoWallets from 'src/pages/backoffice/Wallets/NoWallets';
import { resetWalletsFilters, updateWalletsFiltersQuery } from 'src/pages/backoffice/Wallets/WalletsFilters/actions';
import { getChunkWalletList, getWalletsList } from 'src/pages/backoffice/Wallets/WalletsList/actions';
import TableHeader from 'src/pages/backoffice/Wallets/WalletsList/TableHeader';
import WalletsRow from 'src/pages/backoffice/Wallets/WalletsList/WalletsRow';
import React, { FC } from 'react';
import { batch, useDispatch } from 'react-redux';
import selector, { chunkLoaderSelector, getNoDataContainerData } from './selector';

const SCROLLABLE_CONTAINER_ID = 'wallets-list';

const containerStyles: SxProps<Theme> = {
  overflow: 'scroll',
  height: '100%',
};

const WalletsList: FC = () => {
  const { items, amountFormatter, getCurrencyCode, isFiltersApplied } = useAppSelector(selector);
  const dispatch = useDispatch();

  const handleClearFilters = () => {
    batch(() => {
      dispatch(resetWalletsFilters());
      dispatch(updateWalletsFiltersQuery());
      dispatch(getWalletsList(null));
    });
  };

  const noDataContent = isFiltersApplied ? (
    <NoFiltratedDataPlaceholder clearFilters={handleClearFilters} />
  ) : (
    <NoWallets />
  );

  return (
    <>
      <ScrollMemory elementID={SCROLLABLE_CONTAINER_ID} />

      <NoDataContainer
        sx={containerStyles}
        selector={getNoDataContainerData}
        loader={<TableSkeleton rows={10} />}
        noDataContent={noDataContent}
        id={SCROLLABLE_CONTAINER_ID}
      >
        <Grid item width="100%" height="100%">
          <Table stickyHeader>
            <TableHeader />

            <TableBody>
              {items?.map((item) => (
                <WalletsRow
                  key={item.id}
                  item={item}
                  amountFormatter={amountFormatter}
                  getCurrencyCode={getCurrencyCode}
                />
              ))}
            </TableBody>
          </Table>

          <ChunkLoader selector={chunkLoaderSelector} getChunk={getChunkWalletList} />
        </Grid>
      </NoDataContainer>
    </>
  );
};

export default WalletsList;
