import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

interface IRowProps {
  title: string;
  containerProps?: unknown;
}

const RequestInfoRow: FC<IRowProps> = (props) => {
  const { title, containerProps, children } = props;

  return (
    <Grid item container direction="column" rowGap={1} wrap="nowrap">
      <Grid item xs={4}>
        <Typography variant="body2" color="text.secondary">
          <FormattedMessage id={title} />
        </Typography>
      </Grid>

      <Grid item {...containerProps}>
        {children}
      </Grid>
    </Grid>
  );
};

export default RequestInfoRow;
