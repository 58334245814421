import { COLOURS } from '@core-ui/styles';
import Grid from '@mui/material/Grid';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { FC } from 'react';
import { heightContainerMarker } from 'src/pages/backoffice/Requests/SingleRequest/consts';
import QuoteBar from '../QuoteBar';
import QuoteCell from '../QuoteCell';
import selector from './selector';
import useStyles from './styles';

interface IProps {}

const QuotesColumns: FC<IProps> = () => {
  const { quotes, averageHeight, maxValue } = useAppSelector(selector);

  const styles = useStyles({
    averageHeight,
  });

  return (
    <Grid container overflow="scroll">
      <Grid
        container
        flexGrow={1}
        wrap="nowrap"
        width="auto"
        maxHeight={heightContainerMarker}
        sx={styles.quoteContainerHead}
        borderBottom={`1px solid ${COLOURS.BORDER.MAIN}`}
      >
        <Grid sx={styles.quoteContainerMarker}>
          <Grid width="100%" borderTop={`1px dashed ${COLOURS.HUE_COCONUT.HUE_172}`} sx={styles.quoteMarker} />
        </Grid>

        {quotes.map((quote) => (
          <Grid key={quote.id}>
            <QuoteBar quote={quote} maxValue={maxValue} />
          </Grid>
        ))}
      </Grid>

      <Grid container wrap="nowrap">
        {quotes.map((quote) => (
          <Grid key={quote.id}>
            <QuoteCell quote={quote} />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default QuotesColumns;
