import { COLOURS } from '@core-ui/styles';
import { Theme } from '@mui/material/styles/createTheme';
import { SxProps } from '@mui/system/styleFunctionSx/styleFunctionSx';

export default (props: { expanded: boolean; }) => ({
  root: {
    borderRadius: 4,
    backgroundColor: props.expanded ? COLOURS.BACKGROUND.SECONDARY: COLOURS.WHITE,

    '&:nth-of-type(even)': {
      backgroundColor: COLOURS.BACKGROUND.SECONDARY
    }
  }
}) as Record<string, SxProps<Theme>>;
