import { COLOURS } from '@core-ui/styles';
import { IMenuOption } from 'src/components/Menu/Menu';
import MoreButton from 'src/components/MoreButton';
import { FileStatusEnum, WalletRecordSchema } from 'src/generated';
import { openBackofficeModal } from 'src/pages/backoffice/Modals/actions';
import { DeleteWalletTransactionModalData } from 'src/pages/backoffice/Modals/command';
import { Trash } from '@phosphor-icons/react';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

export interface IProps {
  item: WalletRecordSchema;
}

const SingleWalletRowMoreButton: FC<IProps> = (props) => {
  const { item } = props;
  const dispatch = useDispatch();

  const handleDelete = () => {
    // TODO: we are not sure about UNKNOWN status, need to check which endpoint should be used for this type of file

    // only already digitized files have document_id. We use /files/{file_id} route for deleting
    // not yet digitized and returned files and /document/{document_id} route for deleting already digitized
    const id = item.status === (FileStatusEnum.DIGITIZED || FileStatusEnum.UNKNOWN) ? item.document_id : item.id;

    if (id) {
      dispatch(openBackofficeModal(new DeleteWalletTransactionModalData(id, item.status)));
    }
  };

  const options: IMenuOption[] = [
    {
      title: <FormattedMessage id="label.delete" />,
      dataTestId: 'wallet-transaction-more-button-delete',
      textColor: COLOURS.HUE_STRAWBERRY.HUE_100,
      icon: <Trash color={COLOURS.HUE_STRAWBERRY.HUE_100} />,
      onClick: handleDelete,
      visible: true,
    },
  ];

  return <MoreButton options={options} dataTestId="single-wallet-row-more-button" />;
};

export default SingleWalletRowMoreButton;
