import State from 'src/app/types/state';
import { heightPercent } from 'src/pages/backoffice/Requests/SingleRequest/consts';
import uniqBy from 'lodash-es/uniqBy';
import { createSelector } from 'reselect';
import { REQUEST_DOCUMENTS_TYPE_ENUM } from 'src/pages/backoffice/Requests/types';

const getDocuments = (state: State) => state.backoffice.requests.singleRequest.compareQuote.quotes;
const quotesItems = (state: State) => state.backoffice.requests.singleRequest.request.value;

const getQuotes = createSelector([getDocuments], (documents) =>
  documents.filter((document) => document.document_type === REQUEST_DOCUMENTS_TYPE_ENUM.QUOTE)
);

export const getCostArticle = createSelector([getQuotes], (documents) => {
  const lines = documents.map((item) => item?.lines ?? []).flat();

  return uniqBy(lines, 'cost_article');
});

const getMaxHeight = createSelector([getQuotes], (item) => {
  const totals = item.map((el) => el.total_price);

  return Math.max(...totals);
});

export const getAverage = createSelector([getQuotes, getMaxHeight], (item, maxHeight) => {
  const totals = item.map((el) => el.total_price);
  const averageMax = totals.reduce((acc, current) => acc + current, 0) / totals.length;

  return (averageMax * heightPercent) / maxHeight;
});

const getAverageTotal = createSelector([getQuotes], (item) => {
  const totals = item.map((el) => el.total_price);

  return totals.reduce((acc, current) => acc + current, 0) / totals.length;
});

export default createSelector(
  [getCostArticle, getAverage, getAverageTotal, quotesItems],
  (articles, averageHeight, averageTotal, item) => ({
    articles,
    averageHeight,
    averageTotal,
    item,
  })
);
