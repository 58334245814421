import reducer from '@core-ui/reducers_handlers';
import { Action, handleActions } from 'redux-actions';
import Immutable from '@core-ui/immutable';
import { Nullable } from '@core-ui/types';
import * as actions from './actions';
import { IDeleteInvoice } from './actions';

export interface IInvoicesListState {
  checkedInvoicesId: Record<number, boolean>;
  documentId: Nullable<number>;
  isDocumentAlreadyDigitized: Nullable<boolean>;
}

const defaultState = Immutable<IInvoicesListState>({
  checkedInvoicesId: {},
  documentId: null,
  isDocumentAlreadyDigitized: null,
});

export default handleActions<typeof defaultState, any>(
  {
    [actions.toggleInvoiceId.toString()]: (state, { payload }: Action<number>) => {
      return state.setIn(['checkedInvoicesId', payload], !state.checkedInvoicesId[payload]);
    },
    [actions.deleteInvoice.toString()]: (state, { payload }: Action<IDeleteInvoice>) => {
      return state.set('isDocumentAlreadyDigitized', payload.isDocumentAlreadyDigitized);
    },
  },
  reducer<typeof defaultState>(actions.reducerName, defaultState)
);
