import reducer from '@core-ui/reducers_handlers';
import { Action, handleActions } from 'redux-actions';
import Immutable from '@core-ui/immutable';
import * as actions from './actions';

export interface ISingleWalletDragNDropState {
  placeholders: string[];
  isLoading: boolean;
}

const defaultState = Immutable<ISingleWalletDragNDropState>({
  placeholders: [],
  isLoading: false,
});

export default handleActions<typeof defaultState, any>(
  {
    [actions.setLoading.toString()]: (state, { payload }: Action<boolean>) => {
      return state.set('isLoading', payload);
    },
    [actions.setPlaceholders.toString()]: (state, { payload }: Action<string[]>) => {
      return state.set('placeholders', payload);
    },
  },
  reducer<typeof defaultState>(actions.reducerName, defaultState)
);
