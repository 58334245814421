import { COLOURS } from '@core-ui/styles';
import { SxProps, Theme } from '@mui/material/styles';
import { COMPACT_HEADER_HEIGHT, PADDING_Y } from 'src/pages/backoffice/consts';

export default (): Record<string, SxProps<Theme>> => ({
  container: {
    padding: 6,
    position: 'sticky',
    top: COMPACT_HEADER_HEIGHT - PADDING_Y,
    zIndex: 2,
    background: COLOURS.HUE_COCONUT.HUE_16,
  },
});
