import Button from '@core-ui/button';
import { buildURL } from '@core-ui/url';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { ROUTES } from 'src/app/consts/routes';
import history from 'src/history';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { BACKOFFICE_TABS } from 'src/pages/backoffice/consts';
import { ArrowLeft } from '@phosphor-icons/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import selector from './selector';
import useStyles from './styles';

const SingleWalletHeaderSmall = () => {
  const { wallet, formattedAmountWithCurrency } = useAppSelector(selector);
  const styles = useStyles();

  const handleBackToWallets = () => {
    if (wallet) {
      history.push(buildURL(`/${wallet.boat_id}/${ROUTES.BACKOFFICE}/${BACKOFFICE_TABS.WALLETS}`));
    }
  };

  const walletTitle = wallet ? (
    <span>{wallet?.name}</span>
  ) : (
    <FormattedMessage id="label.no_info" />
  );

  return (
    <Grid item container padding="24px 0" gap={2} sx={styles.container}>
      <Button
        variant="text"
        color="blue"
        compact
        label={<FormattedMessage id="label.back_to_wallets" />}
        startIcon={<ArrowLeft />}
        onClick={handleBackToWallets}
      />
      <Typography fontWeight="semiBold" lineHeight="24px">
        /
      </Typography>

      <Typography fontWeight="semiBold" lineHeight="24px">
        {walletTitle}
        {', '}

        <span>{formattedAmountWithCurrency}</span>
      </Typography>
    </Grid>
  );
};

export default SingleWalletHeaderSmall;
