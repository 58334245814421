import { COLOURS } from '@core-ui/styles';
import { IMenuOption } from 'src/components/Menu/Menu';
import MoreButton from 'src/components/MoreButton';
import { DownloadSimple, Trash } from '@phosphor-icons/react';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

interface IProps {
  onDownload: () => void;
  onDelete: () => void;
}

const InvoicesMoreButton: FC<IProps> = (props) => {
  const { onDownload, onDelete } = props;

  const options: IMenuOption[] = [
    {
      title: <FormattedMessage id="label.download" />,
      icon: <DownloadSimple />,
      onClick: onDownload,
      visible: true,
    },
    {
      title: <FormattedMessage id="label.delete" />,
      textColor: COLOURS.HUE_STRAWBERRY.HUE_100,
      icon: <Trash color="red" />,
      onClick: onDelete,
      visible: true,
    },
  ];

  return <MoreButton options={options} variant="square" />;
};

export default InvoicesMoreButton;
