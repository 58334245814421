import { DEBOUNCE_PERIOD } from '@core-ui/consts';
import { updateQuery } from '@core-ui/url';
import { ISagaContext } from 'src/app/types/common';
import { IRequestsFiltersState } from 'src/pages/backoffice/Requests/RequestFilters/reducer';
import { debounce, getContext, select, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { getFilters as filterSelector } from './selector';

function* filterToQuerySaga() {
  const filters: IRequestsFiltersState = yield select(filterSelector);
  const history: ISagaContext['history'] = yield getContext('history');

  // we don't save request status in the query
  const filtersToUpdateQuery = {
    ...filters,
    status: undefined,
  };

  history.replace(updateQuery(filtersToUpdateQuery));
}

export default [
  debounce(DEBOUNCE_PERIOD, actions.setTextFilter, filterToQuerySaga),
  takeLatest(actions.updateRequestsFiltersQuery, filterToQuerySaga),
];
