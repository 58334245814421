import Button from '@core-ui/button';
import { COLOURS, HEIGHT_SIZE } from '@core-ui/styles';
import { buildURL, getParsedQuery } from '@core-ui/url';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { SxProps, Theme } from '@mui/material/styles';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import { ROUTES } from 'src/app/consts/routes';
import { showNotification } from 'src/components/Notification/actions';
import StatusBadge from 'src/components/StatusBadge';
import history from 'src/history';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { BACKOFFICE_TABS } from 'src/pages/backoffice/consts';
import { sendToERP } from 'src/pages/backoffice/Requests/SingleRequest/QuotesList/actions';
import SingleRequestMoreButton from 'src/pages/backoffice/Requests/SingleRequest/SingleRequestRoot/components/SingleRequestMoreButton';
import { RequestDetailSkeleton } from 'src/pages/backoffice/Requests/skeletons';
import { PAYMENT_REQUEST_TYPE_ENUM } from 'src/pages/backoffice/Requests/types';
import { ChatCircleDots, File } from '@phosphor-icons/react';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import QuoteViewButton from '../components/QuoteViewButton';
import UploadDocumentButton from '../components/UploadDocumentButton';
import InvoicesList from '../InvoicesList';
import QuotesList from '../QuotesList';
import RequestInfo from '../RequestInfo';
import { DETAIL_REQUEST_TABS } from '../types';
import SingleRequestButtons from './components/SingleRequestButtons';
import SingleRequestTabs from './components/SingleRequestTabs';
import selector from './selector';

const containerStyles: SxProps<Theme> = {
  padding: '0 24px !important',
  height: `calc(100vh - (${HEIGHT_SIZE.headerHeight + HEIGHT_SIZE.tabsHeight}px))`,
  overflow: 'hidden',
};

const SingleRequestRoot = () => {
  const { boatId, requestId, tabId = DETAIL_REQUEST_TABS.ABOUT } = useParams<any>();
  const { request, requestEnabled } = useAppSelector(selector);
  const dispatch = useDispatch();

  useEffect(() => {
    const search = getParsedQuery({ arrayFormat: 'index' });

    if (search.error) {
      dispatch(
        showNotification({
          variant: 'error',
          titleId: 'notification.error.text.xero_default',
          message: '',
        })
      );
    }

    if (search.authorized === 'True' && search.document_id && search.invoice_code && request) {
      dispatch(
        sendToERP({
          requestId: request.id,
          clientCostArticleCode: Number(search.invoice_code),
          invoiceDocumentId: Number(search.document_id),
          secondTry: true,
        })
      );
    }
  }, [request, dispatch]);

  if (!request) {
    return <RequestDetailSkeleton boatId={boatId} />;
  }

  const goToBackoffice = () => {
    history.push(buildURL(`/${boatId}/${ROUTES.BACKOFFICE}`));
  };

  const goToRequests = () => {
    history.push(buildURL(`/${boatId}/${ROUTES.BACKOFFICE}/${BACKOFFICE_TABS.REQUESTS}`));
  };

  return (
    <Container maxWidth="xl" sx={containerStyles}>
      <Grid container direction="column" marginTop={6}>
        <Breadcrumbs aria-label="breadcrumb">
          <Button
            compact
            onClick={goToBackoffice}
            label={
              <Typography variant="body2" color="text.secondary">
                <FormattedMessage id="menu.backoffice" />
              </Typography>
            }
          />

          <Button
            compact
            onClick={goToRequests}
            label={
              <Typography variant="body2" color="text.secondary">
                <FormattedMessage id="backoffice.tabs.requests" />
              </Typography>
            }
          />
        </Breadcrumbs>

        <Grid item container flexWrap="nowrap" columnGap={1} marginTop={1}>
          <Grid item container xs alignItems="center" gap={4}>
            <Grid item component={Typography} variant="h3" color={requestEnabled ? 'text.primary' : 'text.secondary'}>
              {request.name}
            </Grid>

            <SingleRequestMoreButton isRequestEnabled={requestEnabled} />
          </Grid>

          <SingleRequestButtons request={request} />
        </Grid>
      </Grid>

      <Grid container alignItems="center" columnGap={1} marginTop={2}>
        <StatusBadge status={request.calculated_status} />

        <Grid item container columnGap={3} wrap="nowrap" justifyContent="flex-end" width="auto">
          <Grid item container alignItems="center" columnGap={1} wrap="nowrap">
            <SvgIcon htmlColor={COLOURS.HUE_COCONUT.HUE_100} fontSize="small">
              <ChatCircleDots />
            </SvgIcon>

            <Typography variant="body2" color={COLOURS.HUE_COCONUT.HUE_100}>
              {request.comments?.length || 0}
            </Typography>
          </Grid>

          <Grid item container alignItems="center" columnGap={1} wrap="nowrap">
            <SvgIcon htmlColor={COLOURS.HUE_COCONUT.HUE_100} fontSize="small">
              <File />
            </SvgIcon>

            <Typography variant="body2" color={COLOURS.HUE_COCONUT.HUE_100}>
              {request.files_number ?? 0}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid container wrap="nowrap" alignItems="center" marginTop={4}>
        <Grid item xs>
          <SingleRequestTabs
            boatId={boatId}
            tabId={tabId}
            requestId={requestId}
            requestType={request.request_type}
            chatId={request.chat_id}
          />
        </Grid>

        {tabId === DETAIL_REQUEST_TABS.QUOTES && (
          <Grid paddingRight={4}>
            <QuoteViewButton />
          </Grid>
        )}

        {requestEnabled && (
          <Grid item>
            <UploadDocumentButton />
          </Grid>
        )}
      </Grid>

      {tabId === DETAIL_REQUEST_TABS.ABOUT && <RequestInfo />}

      {tabId === DETAIL_REQUEST_TABS.QUOTES && (
        <QuotesList requestId={request.id} isEmergency={request.request_type === PAYMENT_REQUEST_TYPE_ENUM.EMERGENCY} />
      )}

      {tabId === DETAIL_REQUEST_TABS.INVOICES && <InvoicesList requestId={request.id} />}
    </Container>
  );
};

export default SingleRequestRoot;
