import State from 'src/app/types/state';
import { getUploadedByOptions } from 'src/dictionary/selector';
import { ISingleWalletFiltersState } from 'src/pages/backoffice/Wallets/SingleWalletFilters/reducer';
import { createSelector } from 'reselect';

export const keys: Array<keyof ISingleWalletFiltersState> = ['search', 'dateFrom', 'dateTo', 'uploadedBy', 'status'];

export const getSingleWalletFilters = (state: State) => state.backoffice.wallets.singleWalletFilters;

export default createSelector([getSingleWalletFilters, getUploadedByOptions], (filters, uploadedByOptions) => ({
  filters: {
    ...filters,

    uploadedBy: Array.isArray(filters.uploadedBy) ? filters.uploadedBy : [filters.uploadedBy],
    status: Array.isArray(filters.status) ? filters.status : [filters.status],
  },
  uploadedByOptions,
}));
