import { getChunkList, getList, resetState, setChunkList, setList, setLoading } from '@core-ui/reducers_handlers';
import { SORT_ORDER_ENUM } from '@core-ui/types';
import { WalletRecordSchema } from 'src/generated';
import { createAction } from 'redux-actions';

export const reducerName = 'wallets.singleWallet.list';

export const getSingleWalletList = getList(reducerName);
export const setSingleWalletList = setList<WalletRecordSchema>(reducerName);

export const getChunkSingleWalletList = getChunkList(reducerName);
export const setChunkSingleWalletList = setChunkList<WalletRecordSchema>(reducerName);

export const setOrderBy = createAction<string>(`${reducerName}.SET_ORDER_BY`);
export const setOrder = createAction<SORT_ORDER_ENUM>(`${reducerName}.SET_ORDER`);

export const setPaginationPage = createAction<number>(`${reducerName}.SET_PAGINATION_PAGE`);

export const setSingleWalletListLoading = setLoading(reducerName);
export const resetSingleWalletList = resetState(reducerName);

export const setTotal = createAction<number>(`${reducerName}.SET_TOTAL`);
