import Button from '@core-ui/button';
import { buildURL } from '@core-ui/url';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { ROUTES } from 'src/app/consts/routes';
import history from 'src/history';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { BACKOFFICE_TABS } from 'src/pages/backoffice/consts';
import { ArrowLeft } from '@phosphor-icons/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import selector from './selector';
import SingleWalletHeaderMoreButton from './SingleWalletHeaderMoreButton';
import useStyles from './styles';

const SingleWalletHeader = () => {
  const { wallet, formattedTotal } = useAppSelector(selector);
  const styles = useStyles();

  const handleBackToWallets = () => {
    if (wallet) {
      history.push(buildURL(`/${wallet.boat_id}/${ROUTES.BACKOFFICE}/${BACKOFFICE_TABS.WALLETS}`));
    }
  };

  const walletTitle = wallet ? (
    <>
      <Typography variant="h3">{wallet?.name}</Typography>

      <SingleWalletHeaderMoreButton wallet={wallet} />
    </>
  ) : (
    <FormattedMessage id="label.no_info" />
  );

  return (
    <Grid item container direction="column" padding="24px 0" sx={styles.container}>
      <Grid item container width="100%" marginBottom={2} justifyContent="space-between">
        <Button
          variant="text"
          color="blue"
          compact
          label={<FormattedMessage id="label.back_to_wallets" />}
          startIcon={<ArrowLeft />}
          onClick={handleBackToWallets}
        />
      </Grid>

      <Grid item container marginBottom={3} gap={4}>
        {walletTitle}
      </Grid>

      <Grid item>
        <Typography fontWeight="semiBold">
          {formattedTotal}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default SingleWalletHeader;
