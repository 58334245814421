import { HEIGHT_SIZE } from '@core-ui/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useAppSelector } from 'src/hooks/useAppSelector';
import React, { FC } from 'react';
import TableMap from './components/TableMap';
import TableView from './components/TableView';
import selector from './selector';

const TableViewRequests: FC = () => {
  const { groupedStatusList } = useAppSelector(selector);

  return (
    <Grid container columnGap={1}>
      <Grid
        item
        component={Paper}
        container
        direction="column"
        wrap="nowrap"
        alignItems="flex-start"
        overflow="scroll"
        // fixme: 82px - высота фильтров. нужно избавиться
        sx={{
          padding: '17px 24px',
          maxHeight: `calc(100vh - ${HEIGHT_SIZE.headerHeight + HEIGHT_SIZE.tabsHeight + 82}px)`,
        }}
        xs
      >
        <Grid
          container
          wrap="nowrap"
          paddingBottom="16px"
          borderBottom="1px solid #EFF1F3"
          justifyContent="space-between"
        >
          <Grid item component={Typography} variant="body2" fontWeight="semiBold" color="text.secondary" xs={4} noWrap>
            Request name
          </Grid>
          <Grid item component={Typography} color="text.secondary" xs noWrap variant="body2" fontWeight="semiBold">
            Status
          </Grid>
          <Grid item component={Typography} color="text.secondary" xs noWrap variant="body2" fontWeight="semiBold">
            Creator
          </Grid>
          <Grid item component={Typography} color="text.secondary" xs noWrap variant="body2" fontWeight="semiBold">
            Department
          </Grid>
          <Grid item component={Typography} color="text.secondary" xs noWrap variant="body2" fontWeight="semiBold">
            Open
          </Grid>
          <Grid item component={Typography} color="text.secondary" xs noWrap variant="body2" fontWeight="semiBold">
            Deadline
          </Grid>
        </Grid>

        {groupedStatusList.map((groupedStatus) => (
          <TableView key={groupedStatus.title} title={groupedStatus.title} selector={groupedStatus.selector} />
        ))}
      </Grid>
      <TableMap />
    </Grid>
  );
};

export default TableViewRequests;
