import reducer, { Data, LoadingItems } from '@core-ui/reducers_handlers';
import { handleActions } from 'redux-actions';
import Immutable from '@core-ui/immutable';
import { IPaymentRequest } from 'src/pages/backoffice/Requests/types';
import * as actions from './actions';

export interface IPaymentRequestState extends Data<IPaymentRequest>, LoadingItems {
  isERPLoading: boolean;
}

const defaultState = Immutable<IPaymentRequestState>({
  loaded: false,
  loading: false,
  hasData: false,
  isERPLoading: false,
});

export default handleActions<typeof defaultState, unknown>(
  {
    [actions.setERPLoading.toString()]: (state, { payload }) => {
      return state.set('isERPLoading', payload);
    },
  },
  reducer<typeof defaultState>(actions.reducerName, defaultState)
);
