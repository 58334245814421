import RadioButton from '@core-ui/radio_button';
import { COLOURS } from '@core-ui/styles';
import Grid from '@mui/material/Grid';
import {
  heightQuoteBar,
  maxHeightQuoteBar,
  widthQuoteBar,
  widthQuoteColumn,
} from 'src/pages/backoffice/Requests/SingleRequest/consts';
import { IQuote, IQuoteLine, QUOTE_STATUS_ENUM } from 'src/pages/backoffice/Requests/types';
import { FC, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setRadioCheckedQuotesById } from '../../actions';
import { scaleBar } from './scaleBar';
import useStyles from './styles';

interface IProps {
  quote: IQuote;
  maxValue: number;
}

const QuoteBar: FC<IProps> = (props) => {
  const { quote, maxValue } = props;

  const [active, setActive] = useState(false);
  const dispatch = useDispatch();

  const quoteDeclined = quote.document_approve_status === QUOTE_STATUS_ENUM.DECLINED;

  const styles = useStyles({
    active,
  });

  const bars = useMemo(() => scaleBar(quote, maxValue), [quote, maxValue]);

  const handleBarClick = () => {
    dispatch(setRadioCheckedQuotesById({ id: quote.id }));

    if (quoteDeclined) {
      setActive(false);
    } else {
      setActive(!active);
    }
  };

  return (
    <Grid width={widthQuoteColumn}>
      <Grid container paddingLeft={3} paddingTop={4} width={widthQuoteBar}>
        <Grid
          container
          sx={styles.barActive}
          justifyContent="center"
          paddingLeft={3}
          paddingRight={3}
          height={heightQuoteBar}
          onClick={handleBarClick}
        >
          <Grid item textAlign="center" alignSelf="flex-start" width="100%" paddingTop={1}>
            <RadioButton
              size="large"
              color={COLOURS.BLACK}
              checked={quoteDeclined ? false : active}
              disabled={quoteDeclined}
            />
          </Grid>

          <Grid
            container
            gap="2px"
            alignSelf="flex-end"
            justifyContent="flex-end"
            wrap="nowrap"
            flexDirection="column"
            height={maxHeightQuoteBar}
            overflow="hidden"
          >
            {bars.map((line: IQuoteLine) => (
              <Grid
                key={line.total_price}
                item
                bgcolor={line.color}
                borderRadius={1}
                height={`${line.total_price > 2 ? line.total_price : 2}%`}
                width="100%"
              />
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default QuoteBar;
