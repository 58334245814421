import Button from '@core-ui/button';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { openBackofficeModal } from 'src/pages/backoffice/Modals/actions';
import { RejectRequestModalData } from 'src/pages/backoffice/Modals/command';
import { updateInvoiceStatus } from 'src/pages/backoffice/Requests/SingleRequest/InvoicesList/actions';
import {
  CALCULATED_STATUS,
  IPaymentRequest,
  PAYMENT_REQUEST_APPROVE_STATUS,
  PAYMENT_REQUEST_STATUS_ENUM,
} from 'src/pages/backoffice/Requests/types';
import { CheckCircle, X } from '@phosphor-icons/react';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { updateRequestStatus } from '../../actions';
import selector from './selector';

interface IProps {
  request: IPaymentRequest;
}

const SingleRequestButtons: FC<IProps> = (props) => {
  const { request } = props;
  const { approve_status, calculated_status, request_status } = request;

  const dispatch = useDispatch();
  const { user, loading } = useAppSelector((state) => selector(state, { requestId: request.id }));

  const handleApproveClick = () =>
    dispatch(
      updateInvoiceStatus({
        status: PAYMENT_REQUEST_APPROVE_STATUS.APPROVED,
      })
    );

  const handleDeclineClick = () =>
    dispatch(
      updateInvoiceStatus({
        status: PAYMENT_REQUEST_APPROVE_STATUS.DECLINED,
      })
    );

  const handleRejectClick = () => {
    dispatch(openBackofficeModal(new RejectRequestModalData(request.id)));
  };

  const handleDoneClick = () => {
    dispatch(updateRequestStatus());
  };

  if (calculated_status === CALCULATED_STATUS.PAID) {
    return (
      <Button
        variant="contained"
        size="small"
        color="blue"
        onClick={handleDoneClick}
        label={<FormattedMessage id="request.button.done" />}
      />
    );
  }

  if (
    (approve_status !== PAYMENT_REQUEST_APPROVE_STATUS.AWAITING &&
      calculated_status !== CALCULATED_STATUS.REQUEST_AWAITING_APPROVAL &&
      request_status !== PAYMENT_REQUEST_STATUS_ENUM.SUBSTANCE) ||
    user?.type !== 'office'
  ) {
    return null;
  }

  return (
    <>
      <Button
        variant="contained"
        size="small"
        color="blue"
        onClick={handleApproveClick}
        label={
          <FormattedMessage
            id={approve_status === PAYMENT_REQUEST_APPROVE_STATUS.APPROVED ? 'approved' : 'approve_request'}
          />
        }
        startIcon={<CheckCircle />}
        loading={loading}
        disabled={loading}
      />
      {approve_status === PAYMENT_REQUEST_APPROVE_STATUS.DECLINED ? (
        <Button
          variant="outlined"
          size="small"
          onClick={handleDeclineClick}
          startIcon={<X />}
          label={<FormattedMessage id="declined" />}
          loading={loading}
          disabled={loading}
        />
      ) : (
        <Button
          variant="outlined"
          size="small"
          onClick={handleRejectClick}
          startIcon={<X />}
          label={<FormattedMessage id="reject" />}
          loading={loading}
          disabled={loading}
        />
      )}
    </>
  );
};

export default SingleRequestButtons;
