import { getParams } from '@core-ui/redux-router';
import { POST } from 'src/api/oceanApi';
import { responseError } from 'src/app/sagas';
import { ISagaContext } from 'src/app/types/common';
import { showNotification } from 'src/components/Notification/actions';
import { WALLETS_PATHS } from 'src/pages/backoffice/Wallets/routes';
import { getSingleWalletList } from 'src/pages/backoffice/Wallets/SingleWalletList/actions';
import { SingleWalletPathParams } from 'src/pages/backoffice/Wallets/types';
import { Action } from 'redux-actions';
import { all, call, getContext, put, takeEvery } from 'redux-saga/effects';
import * as actions from './actions';

const createFormData = (payload: actions.IUploadDocument, walletId: string) => {
  const formData = new FormData();

  // TODO: добавить append filetype используя утилиту getFileType из ./selector.ts,
  // но на бэке не обработан один эдж кейс, поэтому имплементация отложена
  formData.append('wallet_id', walletId);

  payload.files.forEach((file) => {
    formData.append('fileobjects', file);
  });

  return formData;
};

function* uploadDocument({ payload }: Action<actions.IUploadDocument>) {
  const history: ISagaContext['history'] = yield getContext('history');

  try {
    const params = getParams<SingleWalletPathParams>(history.location.pathname, WALLETS_PATHS);
    const fileNames = payload.files.map((file) => file.name);

    yield all([put(actions.setLoading(true)), put(actions.setPlaceholders(fileNames))]);

    const formData = createFormData(payload, params.walletId);

    yield call(POST, '/files', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    yield all([
      put(getSingleWalletList(null)),
      put(
        showNotification({
          variant: 'success',
          titleId: 'notification.success.text.document_uploaded',
          message: '',
        })
      ),
    ]);
  } catch (e) {
    yield call(responseError, e);
  } finally {
    yield all([put(actions.setLoading(false)), put(actions.setPlaceholders([]))]);
  }
}

export default [takeEvery(actions.uploadDocument, uploadDocument)];
