import walletsSingleWalletListSagas from 'src/pages/backoffice/Wallets/SingleWalletList/sagas';
import singleWalletRowSagas from 'src/pages/backoffice/Wallets/SingleWalletList/SingleWalletRow/sagas';
import singleWalletDragNDropSagas from 'src/pages/backoffice/Wallets/SingleWalletDragNDrop/sagas';
import walletsSingleWalletFiltersSagas from 'src/pages/backoffice/Wallets/SingleWalletFilters/sagas';
import walletsSingleWalletSagas from 'src/pages/backoffice/Wallets/SingleWalletRoot/sagas';
import walletsFiltersSagas from 'src/pages/backoffice/Wallets/WalletsFilters/sagas';
import walletsListSagas from 'src/pages/backoffice/Wallets/WalletsList/sagas';

export default [
  ...walletsListSagas,
  ...walletsFiltersSagas,
  ...walletsSingleWalletSagas,
  ...walletsSingleWalletListSagas,
  ...singleWalletRowSagas,
  ...walletsSingleWalletFiltersSagas,
  ...singleWalletDragNDropSagas,
];
