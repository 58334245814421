import { COLOURS } from '@core-ui/styles';
import { SxProps, Theme } from '@mui/material/styles';

export default (): Record<string, SxProps<Theme>> => ({
  container: {
    padding: '0 16px 24px !important',
    margin: '12px 0 24px !important',
    overflow: 'scroll',
    position: 'relative',
  },
  invoicesHeader: {
    padding: '16px 0 !important',
    position: 'sticky',
    top: 0,
    zIndex: 2,
    backgroundColor: COLOURS.WHITE,
  },
});
