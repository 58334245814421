import { SxProps, Theme } from '@mui/material/styles';

export default (): Record<string, SxProps<Theme>> => ({
  nameCell: {
    cursor: 'pointer',
  },

  rowIconCell: {
    textAlign: 'center',
  },
});
