import { buildURL } from '@core-ui/url';
import { DELETE, POST, PUT } from 'src/api/oceanApi';
import { ROUTES } from 'src/app/consts/routes';
import { responseError } from 'src/app/sagas';
import { vehicleIdSelector } from 'src/app/selectors';
import { showNotification } from 'src/components/Notification/actions';
import { XeroInputBatchSchema, XeroRedirectSchema, XeroResultBatchSchema } from 'src/generated';
import history from 'src/history';
import { BACKOFFICE_TABS } from 'src/pages/backoffice/consts';
import { closeBackofficeModal, toggleModalLoading } from 'src/pages/backoffice/Modals/actions';
import { getRequestsList } from 'src/pages/backoffice/Requests/RequestList/actions';
import { Action } from 'redux-actions';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { getRequest, setLoadingButton, setRequest } from '../QuotesList/actions';
import * as actions from './actions';
import { IDeleteInvoice, ISendInvoicedBatch } from './actions';
import { getCheckedInvoicesId, getRequestId } from './selector';

function* updateInvoiceStatus({ payload }: Action<actions.IUpdateInvoiceStatus>) {
  const { documentId, status, reason } = payload;
  const requestId: number = yield select(getRequestId);

  try {
    yield put(setLoadingButton({ id: documentId || requestId, loading: true }));

    yield call(PUT, '/approves', [
      {
        request_id: requestId,
        document_id: documentId || undefined,
        status,
        reason,
      },
    ]);
    yield put(
      showNotification({
        variant: 'success',
        titleId: 'notification.success',
      })
    );

    yield put(getRequest({ requestId }));
    yield put(closeBackofficeModal());
  } catch (e) {
    yield call(responseError, e);
  } finally {
    yield put(setLoadingButton({ id: documentId || requestId, loading: false }));
  }
}

function* markAsPaid({ payload }: Action<actions.IMarkAsPaid>) {
  const { documentId, status } = payload;
  try {
    yield put(setLoadingButton({ id: documentId, loading: true }));
    const requestId: number = yield select(getRequestId);

    yield call(PUT, '/invoices/paid', {
      request_id: requestId,
      document_id: documentId,
      is_paid: status,
    });
    yield put(
      showNotification({
        variant: 'success',
        titleId: 'notification.success',
      })
    );

    yield put(getRequest({ requestId }));
  } catch (e) {
    yield call(responseError, e);
  } finally {
    yield put(setLoadingButton({ id: documentId, loading: false }));
  }
}

const createFormData = (payload: actions.IUploadInvoices, requestId: string) => {
  const formData = new FormData();
  formData.append('request_id', requestId);
  formData.append('doctype', payload.type);
  payload.files.forEach((file) => formData.append('fileobjects', file));

  return formData;
};

function* uploadInvoices({ payload }: Action<actions.IUploadInvoices>) {
  try {
    yield put(toggleModalLoading());

    const requestId: number = yield select(getRequestId);
    const formData = createFormData(payload, requestId.toString());

    yield call(POST, '/files', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    yield all([
      put(
        showNotification({
          variant: 'success',
          titleId: 'notification.success',
        })
      ),
      put(getRequest({ requestId })),
      put(closeBackofficeModal()),
    ]);
  } catch (e) {
    yield all([call(responseError, e), put(toggleModalLoading())]);
  }
}

function* deleteRequest() {
  try {
    yield put(toggleModalLoading());

    const requestId: number = yield select(getRequestId);
    const boatId: number = yield select(vehicleIdSelector);

    yield call(DELETE, `/request/${requestId}`);

    yield all([
      put(
        showNotification({
          variant: 'success',
          titleId: 'notification.success',
        })
      ),
      put(setRequest({ value: null, hasData: false })),
      put(getRequestsList({})),
    ]);

    history.push(buildURL(`/${boatId}/${ROUTES.BACKOFFICE}/${BACKOFFICE_TABS.REQUESTS}`));
  } catch (e) {
    yield all([call(responseError, e), put(toggleModalLoading())]);
  } finally {
    yield put(closeBackofficeModal());
  }
}

function* sendInvoicesBatch({ payload }: Action<ISendInvoicedBatch>) {
  try {
    yield put(toggleModalLoading());

    const selectedInvoices: Record<number, boolean> = yield select(getCheckedInvoicesId);

    const data = {
      boat_id: payload.boatId,
      request_id: payload.requestId,
      document_ids: [
        ...Object.entries(selectedInvoices).reduce((previousValue: number[], currentValue) => {
          if (currentValue[1]) {
            return [...previousValue, Number(currentValue[0])];
          }

          return previousValue;
        }, []),
      ],
    } as XeroInputBatchSchema;

    const response: XeroResultBatchSchema | XeroRedirectSchema = yield call(POST, '/xero/invoice/send_batch', data);

    if ('login_redirect_uri' in response) {
      window.location.href = response.login_redirect_uri;
    }

    if ('failed' in response && response.failed.length > 0) {
      const message = response.failed.reduce<string[]>((msg, item) => {
        return [...msg, `${item.number}: ${item.error}`];
      }, []);

      yield put(
        showNotification({
          variant: 'error',
          titleId: 'notification.error.text.erp_documents_batch',
          message,
          autoHideDuration: 15000,
        })
      );
    } else {
      yield put(
        showNotification({
          variant: 'success',
          titleId: 'notification.success.text.erp_invoices_batch',
        })
      );
    }
  } catch (e) {
    yield call(responseError, e);
  } finally {
    yield put(closeBackofficeModal());
  }
}

function* deleteInvoice({ payload }: Action<IDeleteInvoice>) {
  try {
    yield put(toggleModalLoading());

    const requestId: number = yield select(getRequestId);

    if (payload.isDocumentAlreadyDigitized) {
      yield call(DELETE, `/document/${payload.documentId}`);
    } else {
      yield call(DELETE, `/files/${payload.documentId}`);
    }

    yield put(getRequest({ requestId }));

    yield all([
      put(closeBackofficeModal()),
      put(
        showNotification({
          variant: 'success',
          titleId: 'notification.success',
        })
      ),
    ]);
  } catch (e) {
    yield all([call(responseError, e), put(toggleModalLoading())]);
  }
}

export default [
  takeLatest(actions.updateInvoiceStatus, updateInvoiceStatus),
  takeLatest(actions.markAsPaid, markAsPaid),
  takeLatest(actions.uploadDocument, uploadInvoices),
  takeLatest(actions.deleteRequest, deleteRequest),
  takeLatest(actions.sendInvoicesBatch, sendInvoicesBatch),
  takeLatest(actions.deleteInvoice, deleteInvoice),
];
