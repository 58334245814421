import React, { FC } from 'react';
import { useDispatch } from 'react-redux';

import Grid from '@mui/material/Grid';
import OlMap from 'src/components/OLMap';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { setTextFilter } from 'src/pages/backoffice/Requests/RequestFilters/actions';
import { IOpenLayerFeature } from 'src/pages/backoffice/Requests/types';

import selector from './selectors';

const TableMap: FC = () => {
  const { features, showMap, selectedFeatures } = useAppSelector(selector);
  const dispatch = useDispatch();

  const onPinSelect = (item: IOpenLayerFeature) => {
    if (selectedFeatures.includes(item.request_id)) {
      dispatch(
        setTextFilter({
          field: 'selectedFeatures',
          value: selectedFeatures.filter((f) => f !== item.request_id),
        })
      );
    } else {
      dispatch(
        setTextFilter({
          field: 'selectedFeatures',
          value: [...selectedFeatures, item.request_id],
        })
      );
    }
  };

  if (!showMap) {
    return null;
  }

  return (
    <Grid item container xs={4} height="calc(100vh - 148px)">
      <OlMap features={features} onPinSelect={onPinSelect} />
    </Grid>
  );
};

export default TableMap;
