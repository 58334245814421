import State from 'src/app/types/state';
import { CALCULATED_STATUS } from 'src/pages/backoffice/Requests/types';
import { createSelector } from 'reselect';

const paymentRequestSelector = (state: State) => state.backoffice.requests.singleRequest.request.value;
export const getRequestId = (state: State) => state.backoffice.requests.singleRequest.request.value?.id;

const requestEnabledSelector = createSelector([paymentRequestSelector], (request) => {
  if (!request) {
    return false;
  }

  return (
    request.calculated_status !== CALCULATED_STATUS.DONE &&
    request.calculated_status !== CALCULATED_STATUS.REQUEST_DECLINED &&
    request.calculated_status !== CALCULATED_STATUS.INVOICE_DECLINED &&
    request.calculated_status !== CALCULATED_STATUS.QUOTE_DECLINED
  );
});

export default createSelector([paymentRequestSelector, requestEnabledSelector], (request, requestEnabled) => ({
  request,
  requestEnabled,
}));
