import State from 'src/app/types/state';
import { exhaustiveGuard } from 'src/app/utils/app';
import {
  IInvoice,
  INVOICE_FILE_UPLOAD_TYPE_ENUM,
  IQuote,
  REQUEST_DOCUMENTS_TYPE_ENUM,
} from 'src/pages/backoffice/Requests/types';
import { createSelector } from 'reselect';

export const getRequestId = (state: State) => state.backoffice.requests.singleRequest.request.value?.id;

const paymentRequestSelector = (state: State) => state.backoffice.requests.singleRequest.request.value;

export const getInvoices = createSelector(
  [paymentRequestSelector],
  (request) =>
    request?.documents?.reduce<IInvoice[]>((acc: IInvoice[], item: IQuote | IInvoice) => {
      switch (item.document_type) {
        case REQUEST_DOCUMENTS_TYPE_ENUM.POSTPAYMENT:
        case REQUEST_DOCUMENTS_TYPE_ENUM.PREPAYMENT:
        case REQUEST_DOCUMENTS_TYPE_ENUM.RECEIPT:
          acc.push(item as IInvoice);
          break;
        case REQUEST_DOCUMENTS_TYPE_ENUM.QUOTE:
          break;
        default:
          return exhaustiveGuard(item.document_type);
      }

      return acc;
    }, []) ?? []
);

export const getInvoiceBeingDigitized = createSelector(
  [paymentRequestSelector],
  (request) =>
    request?.files_tree?.filter(
      (file) =>
        (file.doc_type === INVOICE_FILE_UPLOAD_TYPE_ENUM.POSTPAYMENT ||
          file.doc_type === INVOICE_FILE_UPLOAD_TYPE_ENUM.PREPAYMENT) &&
        !file.is_digitized
    ) || []
);

export const getCheckedInvoicesId = (state: State) =>
  state.backoffice.requests.singleRequest.invoices.checkedInvoicesId;

export const getBatchSendBtnDisabled = createSelector(
  [getCheckedInvoicesId],
  (checkedInvoicesToErp) =>
    Object.keys(checkedInvoicesToErp).length === 0 || Object.values(checkedInvoicesToErp).every((value) => !value)
);

export default createSelector(
  [getInvoices, getInvoiceBeingDigitized, getBatchSendBtnDisabled],
  (invoices, invoiceBeingDigitized, isBatchSendBtnDisabled) => ({
    invoices,
    invoiceBeingDigitized,
    isBatchSendBtnDisabled,
  })
);
